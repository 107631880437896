import { AxiosHeaders } from "axios";

export enum Methods {
	"GET" = "GET",
	"PUT" = "PUT",
	"POST" = "POST",
	"HEAD" = "HEAD",
	"PATCH" = "PATCH",
	"DELETE" = "DELETE",
	"OPTIONS" = "OPTIONS",
}

export type CustomHeaderProperties = AxiosHeaders & {
	Authorization: string;
};

// Input Types
export {
	type LoginInputs,
	type RegisterInputs,
	type ResetPasswordInputs,
	type ResetPasswordConfirmInputs,
} from "../components/Auth";

export { type ItemReportInputs } from "../components/Pages/QrCode";
export {
	type ChangePasswordInputs,
	type PatchUserInputs,
} from "../components/Pages/Profile/Modals";

export { type MemberByPartnerInputs } from "../components/Pages/RentaCar/Modals";

export type LoginGoogleInputs = {
	code: string;
};

export type LoginFacebookInputs = {
	accessToken: string;
};

export type VerifyEmailInputs = {
	key: string;
};

export type ResendVerifyEmailInputs = {
	email: string;
};

export type PatchQrUserInputs = {
	code: string;
	user: number;
};

export type ManageQrUserItemsInputs = {
	items: Item[];
	qrCode: string;
};

export type PatchBranchByPartnerInputs = {
	branch: BranchDTO;
	branchRole: BranchRoleDTO;
};

export type PostNewBranchRole = {
	branch: number;
	member: MemberByBranchDTO;
};

// Response Types
export type BasicAPIResponse = {
	ok?: boolean;
	msg?: string;
	error?: string;
};

export type UserAPIResponse = BasicAPIResponse & {
	user?: User;
};

export type VerifyAuthAPIResponse = BasicAPIResponse & {
	isAuthenticated?: boolean;
};

export type QrAPIResponse = BasicAPIResponse & {
	qr?: Qr;
};

export type QrUserAPIResponse = BasicAPIResponse & {
	qr?: QrUser;
};

export type QrListUserAPIResponse = BasicAPIResponse & {
	count?: number;
	qrs?: QrDTO[];
};

export type UserItemsAPIResponse = BasicAPIResponse & {
	items?: Item[];
};

export type CategoriesAPIResponse = BasicAPIResponse & {
	categories?: Category[];
};

export type ColorsAPIResponse = BasicAPIResponse & {
	colors?: string[];
};

export type ModelsAPIResponse = BasicAPIResponse & {
	models?: string[];
};

export type BranchesByUserAPIResponse = BasicAPIResponse & {
	branchRoles?: BranchRoleDTO[];
};

export type MemberByBranchAPIResponse = BasicAPIResponse & {
	member?: MemberDTO;
};

export type MembersByBranchApiReponse = BasicAPIResponse & {
	count?: number;
	members?: MemberByBranchDTO[];
};

export type MemberByBranchUpdatedApiReponse = BasicAPIResponse & {
	branchRoleUpdated?: MemberByBranch;
};

export type NewMemberByBranchApiReponse = BasicAPIResponse & {
	memberByBranch?: MemberByBranchDTO;
};

export type BrancheByPartnerAPIResponse = BasicAPIResponse & {
	branchRole?: BranchRoleDTO;
};

export type BranchByIdAPIResponse = BasicAPIResponse & {
	branch?: BranchDTO;
};

export type VehiclesByPartnerApiReponse = BasicAPIResponse & {
	count?: number;
	vehicles?: Vehicle[];
};

export type VehicleByBranchApiReponse = BasicAPIResponse & {
	vehicle?: VehicleDTO;
};

// Items Types
export enum QrType {
	KEYTAG = "KEYTAG",
	STICKER = "STICKER",
}

export type QrBase = {
	id: number;
	code: string;
	user: number;
	partner: number;
	active: boolean;
	type: QrType;
};

export type Qr = QrBase & {
	created_date: string;
	assigned_date: string;
	ad_logo_path: string;
	items_limit: number;
	has_vehicle?: boolean;
};

export type QrDTO = QrBase & {
	createdDate: string;
	assignedDate: string;
	adLogoPath: string;
	itemsLimit: number;
	hasVehicle?: boolean;
};

export type QrUser = Qr & {
	items: Item[];
};

export type Item = {
	id?: number;
	qr?: number;
	name: string;
	category: Category;
};

export type Category = {
	id: number;
	name: string;
};

// Partner Types
export type RoleOption = {
	value: number;
	label: string;
};

export type BranchBase = {
	id?: number;
	name: string;
	address?: string;
	main: boolean;
	active: boolean;
	members?: number;
	vehicles?: number;
	email?: string;
};

export type Branch = BranchBase & {
	phone_number: string;
};

export type BranchDTO = BranchBase & {
	phoneNumber: string;
	isNewBranch?: boolean;
};

export type Partner = {
	id: number;
	name: string;
	active: boolean;
};

export type User = {
	id: number;
	email: string;
	first_name: string;
	last_name: string;
	phone_number: string;
	phone_number_alt: string;
	is_active: boolean;
	member?: Member;
};

export type MemberBase = {
	id: number;
	partner: Partner;
};

export type Member = MemberBase & {
	branch_role: BranchRole;
	is_superuser: boolean;
};
export type MemberDTO = MemberBase & {
	branchRole: BranchRole;
	isSuperUser: boolean;
};

export type UserDTO = {
	id: string;
	email: string;
	firstName: string;
	lastName: string;
	phoneNumber: string;
	phoneNumberAlt: string;
	isActive: boolean;
	member?: MemberDTO;
};

export interface GetPathToBranchI {
	branchId: number;
	finalPath: string;
}

export enum BranchRoleToUpdate {
	ADMIN = "STAFF",
	STAFF = "ADMIN",
}
export enum Role {
	ADMIN = "ADMIN",
	STAFF = "STAFF",
}
export type BranchRoleBase = {
	id: number;
	role: Role;
};
export type BranchRole = BranchRoleBase & {
	branch: Branch;
};
export type BranchRoleDTO = BranchRoleBase & {
	branch: BranchDTO;
};

export type PartialMemberDTO = Pick<
	UserDTO,
	"email" | "firstName" | "lastName"
>;

export type PartialMember = Pick<User, "email" | "first_name" | "last_name">;

export type MemberByBranch = Omit<Member, "partner"> & {
	user: PartialMember;
};

export type MemberByBranchDTO = Omit<MemberDTO, "partner"> & {
	user: PartialMemberDTO;
};

export type NewMemberByBranch = {
	email: string;
	branch: number;
	branchRole: Role;
	isSuperUser: boolean;
	firstName: string;
	lastName: string;
};

type VehicleBase = {
	id?: number;
	category: number;
	plate?: string;
	brand: string;
	color: string;
	model: string;
	booked?: boolean;
	code?: string;
	qr?: string;
	branch?: number;
};

export type Vehicle = VehicleBase & {
	booked_by?: string;
	booked_email?: string;
	booked_phone_number?: string;
};

export type VehicleDTO = VehicleBase & {
	bookedBy?: string;
	bookedEmail?: string;
	bookedPhoneNumber?: string;
};

export type Option = {
	value: number | string;
	label: string;
};

export type VehicleReportInputs = {
	phone_number?: string;
	code?: string;
	email?: string;
	message?: string;
};
