import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useReducer,
} from "react";
import { VehicleDTO } from "../services/types";

enum RentCarActionTypes {
	SET_CATEGORIES = "SET_CATEGORIES",
	SET_CLEAR_DATA = "SET_CLEAR_DATA",
	SET_COLORS = "SET_COLORS",
	SET_MODELS = "SET_MODELS",
	SET_QRS = "SET_QRS",
	SET_VEHICLES = "SET_VEHICLES",
}

type RentCarProviderProps = {
	children: ReactNode;
};

type RentCarStateType = {
	categories: Record<number, string>;
	colors: Record<number, string>;
	models: Record<number, string>;
	qrs: Record<number, { code: string; hasVehicle: boolean }>;
	ready: boolean;
	vehicles: VehicleDTO[];
};

type RentCarReducerActionType = {
	categories: RentCarStateType["categories"];
	colors: RentCarStateType["colors"];
	models: RentCarStateType["models"];
	qrs: RentCarStateType["qrs"];
	ready: RentCarStateType["ready"];
	type: keyof typeof RentCarActionTypes;
	vehicles: RentCarStateType["vehicles"];
};

type RentCarContextType = {
	categories: RentCarStateType["categories"];
	colors: RentCarStateType["colors"];
	handleSetCategories: (props: RentCarStateType["categories"]) => void;
	handleSetColors: (props: RentCarStateType["colors"]) => void;
	handleSetModels: (props: RentCarStateType["models"]) => void;
	handleSetQrs: (props: RentCarStateType["qrs"]) => void;
	handleSetVehicles: (props: RentCarStateType["vehicles"]) => void;
	models: RentCarStateType["models"];
	qrs: RentCarStateType["qrs"];
	ready: RentCarStateType["ready"];
	vehicles: RentCarStateType["vehicles"];
};

const RentCarContext = createContext<RentCarContextType>({
	categories: {},
	colors: {},
	models: {},
	ready: false,
	handleSetCategories: (props: RentCarStateType["categories"]) => {},
	handleSetColors: (props: RentCarStateType["colors"]) => {},
	handleSetModels: (props: RentCarStateType["models"]) => {},
	handleSetQrs: (props: RentCarStateType["qrs"]) => {},
	handleSetVehicles: (props: RentCarStateType["vehicles"]) => {},
	qrs: {},
	vehicles: [],
});

function rentCarReducer(
	state: RentCarStateType,
	action: RentCarReducerActionType
) {
	switch (action.type) {
		case RentCarActionTypes.SET_QRS:
			return { ...state, ready: action.ready, qrs: action.qrs };
		case RentCarActionTypes.SET_CATEGORIES:
			return { ...state, categories: action.categories };
		case RentCarActionTypes.SET_COLORS:
			return { ...state, colors: action.colors };
		case RentCarActionTypes.SET_MODELS:
			return { ...state, models: action.models };
		case RentCarActionTypes.SET_VEHICLES:
			return { ...state, vehicles: action.vehicles };
		case RentCarActionTypes.SET_CLEAR_DATA:
			return {
				...state,
				categories: {},
				colors: {},
				models: {},
				qrs: {},
				ready: false,
				vehicles: [],
			};
		default:
			return state;
	}
}

function RentCarProvider({ children }: RentCarProviderProps) {
	const [rentCarState, dispatchrentCarState] = useReducer(rentCarReducer, {
		categories: {},
		colors: {},
		models: {},
		qrs: {},
		ready: false,
		vehicles: [],
	});

	const handleSetQrs = useCallback(
		(qrs: Record<number, { code: string; hasVehicle: boolean }>) => {
			dispatchrentCarState({
				type: RentCarActionTypes.SET_QRS,
				qrs,
				ready: true,
			} as RentCarReducerActionType);
		},
		[]
	);

	const handleSetCategories = useCallback(
		(categories: Record<number, string>) => {
			dispatchrentCarState({
				type: RentCarActionTypes.SET_CATEGORIES,
				categories,
			} as RentCarReducerActionType);
		},
		[]
	);

	const handleSetColors = useCallback((colors: Record<number, string>) => {
		dispatchrentCarState({
			type: RentCarActionTypes.SET_COLORS,
			colors,
		} as RentCarReducerActionType);
	}, []);

	const handleSetModels = useCallback((models: Record<number, string>) => {
		dispatchrentCarState({
			type: RentCarActionTypes.SET_MODELS,
			models,
		} as RentCarReducerActionType);
	}, []);

	const handleSetVehicles = useCallback((vehicles: VehicleDTO[]) => {
		dispatchrentCarState({
			type: RentCarActionTypes.SET_VEHICLES,
			vehicles: vehicles,
		} as RentCarReducerActionType);
	}, []);

	return (
		<RentCarContext.Provider
			value={{
				...rentCarState,
				handleSetCategories,
				handleSetColors,
				handleSetModels,
				handleSetQrs,
				handleSetVehicles,
			}}
		>
			{children}
		</RentCarContext.Provider>
	);
}

export const useRentCarContext = () => {
	const context = useContext(RentCarContext);

	return context;
};

export default RentCarProvider;
