import { useEffect, useRef, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { Brand } from "../../Icons";
import { Button, ModalType } from "../../Common";

import { useUserContext } from "../../../providers/UserProvider";
import { useModalContext } from "../../../providers/ModalProvider";
import { Api, useAuthentication } from "../../../services";

function QRCodeNew() {
	useAuthentication();
	const navigate = useNavigate();
	const { qrCode } = useParams();

	const modalContext = useModalContext();
	const { user, isAuthenticated } = useUserContext();

	const apiCall = useRef<boolean>(false);
	const [ready, setReady] = useState<boolean>(false);
	const [isPartnerQr, setIsPartnerQr] = useState<boolean>(false);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	useEffect(() => {
		const getQr = async () => {
			setError({ msg: "" });
			if (!qrCode) return;

			try {
				apiCall.current = true;
				const { ok, qr, error } = await Api.getQr(qrCode);

				if (ok) {
					if (qr?.partner) setIsPartnerQr(true);
				} else setError({ msg: error! });
			} catch (e) {
				const error = e as Error;
				setError({ msg: error.message, generic: true });
			} finally {
				apiCall.current = false;
				setReady(true);
			}
		};

		if (!apiCall.current) getQr();
	}, [qrCode]);

	useEffect(() => {
		if (
			isAuthenticated &&
			!modalContext.show &&
			(!user.phoneNumber || !user.phoneNumberAlt)
		)
			modalContext.openModalHandler({
				type: ModalType.UPDATE_PROFILE,
				data: { title: "Update Profile" },
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user, modalContext.show]);

	useEffect(() => {
		if (modalContext.modalResponse === ModalType.ADD_QR) {
			navigate(`/app/qr/${qrCode}`);
		}

		modalContext.clearModalHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalContext.modalResponse]);

	const handleNavigate = (add?: boolean) => {
		if (add) {
			if (isPartnerQr) {
				navigate("/app/partner");
			} else
				modalContext.openModalHandler({
					type: ModalType.ADD_QR,
					data: { title: "Add QR Code", code: qrCode },
				});
		} else navigate("/", {});
	};

	return (
		<>
			{
				<div
					className="
            px-6
            flex
            py-12
            flex-row
            justify-center
            lg:px-8
          "
				>
					<div className="sm:mx-auto sm:w-full sm:max-w-sm">
						{!ready ? (
							<>
								<div className="w-20 mx-auto">
									<Brand size={80} color="fill-green-600" />
								</div>
								<h2
									className="
                    mt-5
                    text-2xl
                    font-bold
                    leading-9
                    text-center
                    text-black
                    tracking-tight
                  "
								>
									Loading QR Code, this could take some seconds wait please.
								</h2>
							</>
						) : (
							<>
								<div className="w-20 mx-auto">
									<Brand size={80} color="fill-green-600" />
								</div>
								<h2
									className="
                    mt-5
                    text-2xl
                    font-bold
                    leading-9
                    text-center
                    text-black
                    tracking-tight
                  "
								>
									New QR Code&nbsp;
									{isPartnerQr ? (
										<span>¿Do you want to add a vehicle to it?</span>
									) : (
										<span>¿Do you want to add it to your account?</span>
									)}
								</h2>

								{error.msg !== "" ? (
									<>
										<div
											className="
                        my-1
                        text-sm
                        text-center
                        font-semibold
                        text-red-600
                      "
										>
											<>
												{error.generic && (
													<p>Something went wrong, please try again later!</p>
												)}
												<p>{error.msg}</p>
											</>
										</div>
										<Button
											onClick={() => handleNavigate()}
											id="new-user-submit-button"
											className="
                        flex
                        mt-10
                        w-full
                        border-2
                        leading-6
                        shadow-sm
                        rounded-md
                        text-white
                        font-semibold
                        justify-center
                        bg-green-600
                        border-green-600
                        hover:bg-green-500
                      "
										>
											Go Home
										</Button>
									</>
								) : (
									<>
										<Button
											onClick={() => handleNavigate(true)}
											id="add-qr-code-button"
											className="
                        flex
                        mt-10
                        w-full
                        border-2
                        leading-6
                        shadow-sm
                        rounded-md
                        text-white
                        font-semibold
                        justify-center
                        bg-green-600
                        border-green-600
                        hover:bg-green-500
                      "
										>
											{isPartnerQr ? "Add Vehicle" : "Add QR Code"}
										</Button>
										<Button
											id="cancel-new-qr-code-button"
											onClick={() => handleNavigate()}
											className="
                        flex
                        mt-5
                        w-full
                        border-2
                        leading-6
                        shadow-sm
                        rounded-md
                        bg-white
                        font-semibold
                        justify-center
                        text-green-600
                        border-green-600
                        hover:bg-green-100
                      "
										>
											Cancel
										</Button>
									</>
								)}
							</>
						)}
					</div>
				</div>
			}
		</>
	);
}

export default QRCodeNew;
