import {
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useReducer,
} from "react";
import { MemberDTO, UserDTO } from "../services/types";
import { Api } from "../services";

export const INITIAL_USER: UserDTO = {
	email: "",
	firstName: "",
	id: "",
	lastName: "",
	phoneNumber: "",
	phoneNumberAlt: "",
	isActive: false,
};

enum UserActionTypes {
	SET_USER = "SET_USER",
	UPDATE_MEMBER_BRANCH = "UPDATE_MEMBER_BRANCH",
}

type UserProviderProps = {
	children: ReactNode;
};

type UserStateType = {
	ready: boolean;
	user: UserDTO;
};

type UserReducerActionType = {
	ready: UserStateType["ready"];
	type: keyof typeof UserActionTypes;
	user: UserStateType["user"];
	member: MemberDTO;
};

type UserContextType = {
	handleSetUser: (props: UserStateType) => void;
	handleUpdateMemberBranch: (branchId: number) => void;
	isAuthenticated: UserStateType["ready"];
	user: UserStateType["user"];
};

const UserContext = createContext<UserContextType>({
	handleSetUser: (props: UserStateType) => {},
	handleUpdateMemberBranch: (branchId: number) => {},
	isAuthenticated: false,
	user: INITIAL_USER,
});

function userReducer(state: UserStateType, action: UserReducerActionType) {
	switch (action.type) {
		case UserActionTypes.SET_USER:
			return { ...state, ready: action.ready, user: action.user };
		case UserActionTypes.UPDATE_MEMBER_BRANCH:
			return {
				...state,
				user: {
					...state.user,
					member: action.member,
				},
			};
		default:
			return state;
	}
}

function UserProvider({ children }: UserProviderProps) {
	const [userState, dispatchUserState] = useReducer(userReducer, {
		user: INITIAL_USER,
		ready: false,
	});

	const handleSetUser = useCallback(({ user, ready }: UserStateType) => {
		dispatchUserState({
			type: UserActionTypes.SET_USER,
			user,
			ready,
		} as UserReducerActionType);
	}, []);

	const handleUpdateMemberBranch = useCallback(
		async (branchId: number) => {
			if (userState.user.member?.isSuperUser) return;

			const updatedMemberResponse = await Api.updateMemberByBranch(
				userState.user.member!.id,
				branchId
			);

			dispatchUserState({
				type: UserActionTypes.UPDATE_MEMBER_BRANCH,
				member: updatedMemberResponse.member,
			} as UserReducerActionType);
		},
		[userState.user.member]
	);

	return (
		<UserContext.Provider
			value={{
				handleSetUser,
				handleUpdateMemberBranch,
				isAuthenticated: userState.ready,
				user: userState.user,
			}}
		>
			{children}
		</UserContext.Provider>
	);
}

export const useUserContext = () => {
	const context = useContext(UserContext);

	return context;
};

export default UserProvider;
