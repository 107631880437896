import { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button, Link, ModalType } from "../../Common";
import { Arrow, Brand, Delete } from "../../Icons";

import { Api } from "../../../services";
import { useBreakpoint } from "../../Hooks";
import { Item, QrUser } from "../../../services/types";
import { useModalContext } from "../../../providers/ModalProvider";

function QrCode() {
	const navigate = useNavigate();
	const { qrCode } = useParams();
	const { isSm } = useBreakpoint("sm");

	const modalContext = useModalContext();

	const apiCall = useRef<boolean>(false);
	const [makeCall, setMakeCall] = useState<boolean>(false);
	const [qrUser, setQrUser] = useState<QrUser | null>(null);
	const [qrUserItems, setQrUserItems] = useState<QrUser["items"]>([]);
	const [qrUserItemsDelete, setQrUserItemsDelete] = useState<QrUser["items"]>(
		[]
	);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	useEffect(() => {
		const getQrItems = async () => {
			if (!qrCode) return;

			try {
				setMakeCall(false);
				apiCall.current = true;
				const { ok, qr, error } = await Api.getQrUser(qrCode);

				if (ok && qr) {
					setQrUser(qr);
					setQrUserItems(qr.items);
				} else setError({ msg: error! });
			} catch (e) {
				const error = e as Error;
				setError({ msg: error.message, generic: true });
			}
		};

		if (!apiCall.current || makeCall) getQrItems();
	}, [qrCode, makeCall]);

	useEffect(() => {
		if (modalContext.modalResponse === ModalType.ADD_ITEM) {
			setQrUserItems((state) => [
				...state.concat([modalContext.modalResponseData as Item]),
			]);
		} else if (modalContext.modalResponse === ModalType.DELETE_ITEM) {
			const item = modalContext.modalResponseData as Item;

			if (item.id) setQrUserItemsDelete((state) => state.concat([item]));
			setQrUserItems((state) => [
				...state.filter(
					(stateItem) =>
						stateItem.id !== item.id || stateItem.name !== item.name
				),
			]);
		}

		modalContext.clearModalHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalContext.modalResponse]);

	const getDate = (dateStr?: string) =>
		dateStr ? new Date(dateStr).toLocaleDateString() : null;

	const handleOnClickItemDelete = (item: Item) => {
		modalContext.openModalHandler({
			type: ModalType.DELETE_ITEM,
			data: { title: "Delete Item", item: item },
		});
	};

	const handleOnClickSaveChanges = async () => {
		setError({ msg: "" });
		const makeCall = [];
		const qrUserItemsAdd = qrUserItems.filter((item) => !item.id);

		try {
			if (qrUserItemsDelete.length > 0) {
				const { ok, error } = await Api.deleteQrUserItems({
					qrCode: qrCode!,
					items: qrUserItemsDelete,
				});
				if (ok) {
					makeCall.push(true);
					setQrUserItemsDelete([]);
				} else setError({ msg: error! });
			}
			if (qrUserItemsAdd.length > 0) {
				const { ok, error } = await Api.postQrUserItems({
					qrCode: qrCode!,
					items: qrUserItemsAdd,
				});
				if (ok) makeCall.push(true);
				else setError({ msg: error! });
			}
		} catch (e) {
			const error = e as Error;
			setError({ msg: error.message, generic: true });
		}

		if (!makeCall.some((flag) => !flag)) setMakeCall(true);
	};

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        min-h-max
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-lg">
				<div className="-ml-5 -mt-5 mb-5 flex flex-row items-center">
					<Link
						icon={true}
						iconPosition="start"
						text="Back to QR Code List"
						onClick={() => navigate("/app/qr", {})}
						customIcon={<Arrow size={20} color="fill-current" />}
					/>
				</div>
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<div className="mt-5 flex flex-row items-center">
					<h2 className="text-black tracking-tight">
						<span className="text-1xl">QR Code: </span>
						<span className="text-2xl font-bold">{qrCode}</span>
					</h2>
				</div>
				<div className="mt-5 flex flex-row items-center justify-between">
					<h2 className="text-black tracking-tight">
						<span className="text-1xl">Items: </span>
						<span className="text-2xl font-bold">
							{qrUserItems.length ?? 0}
						</span>
					</h2>
					<h2 className="text-black tracking-tight">
						<span className="text-1xl">Owned since: </span>
						<span className="text-2xl font-bold">
							{getDate(qrUser?.assigned_date) ?? "N/A"}
						</span>
					</h2>
				</div>
			</div>

			<div className="mt-1 text-sm text-center font-semibold text-red-600">
				{error.msg !== "" && (
					<>
						{error.generic && (
							<p>Something went wrong, please try again later!</p>
						)}
						<p>{error.msg}</p>
					</>
				)}
			</div>

			<div
				className="
          mt-10
          shadow-md
          rounded-md
          overflow-auto
          sm:w-full
          sm:mx-auto
          sm:max-w-xl
        "
			>
				<table className="table w-full text-sm table-fixed">
					<thead className="text-white bg-green-600">
						<tr>
							<th
								style={{ width: "10%" }}
								className="p-3 text-center border-r-white"
							>
								#
							</th>
							<th
								style={{ width: "40%" }}
								className="p-3 text-left border-r-white"
							>
								Item
							</th>
							<th
								style={{ width: "30%" }}
								className="p-3 hidden text-left sm:table-cell border-r-white"
							>
								Category
							</th>
							<th style={{ width: "20%" }} className="p-3 text-center">
								Action
							</th>
						</tr>
					</thead>
					<tbody className="bg-white text-black">
						{qrUserItems.length === 0 && (
							<tr key={0}>
								<td colSpan={isSm ? 3 : 4} className="h-5"></td>
							</tr>
						)}
						{qrUserItems.map((item, index) => (
							<tr key={index}>
								<td className="p-3 font-bold text-center">{index + 1}</td>
								<td className="p-3 text-left">{item.name}</td>
								<td className="p-3 hidden text-left sm:table-cell">
									{item.category.name}
								</td>
								<td className="p-3">
									<div
										onClick={() => handleOnClickItemDelete(item)}
										className="
                      flex
                      text-black
                      items-center
                      cursor-pointer
                      justify-center
                      hover:text-green-600
                  "
									>
										<Delete size={20} color="fill-current" />
									</div>
								</td>
							</tr>
						))}
						{qrUserItems.length! < (qrUser?.items_limit ?? 10) &&
							error.msg === "" && (
								<tr
									key={qrUserItems.length! + 1}
									className="border-t border-green-600"
								>
									<td
										colSpan={isSm ? 3 : 4}
										onClick={() =>
											modalContext.openModalHandler({
												type: ModalType.ADD_ITEM,
												data: { title: "Add Item" },
											})
										}
										className="
                    p-3
                    font-bold
                    text-center
                    cursor-pointer
                    text-green-600
                    hover:text-white
                    hover:bg-green-600
                  "
									>
										Add Item
									</td>
								</tr>
							)}
					</tbody>
				</table>
			</div>

			{(qrUserItemsDelete.length > 0 ||
				qrUserItems.some((item) => !item.id)) && (
				<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-xl">
					<Button
						id="save-change-button"
						onClick={() => handleOnClickSaveChanges()}
						className="
              flex
              mt-5
              w-full
              border-2
              leading-6
              shadow-sm
              rounded-md
              text-white
              font-semibold
              justify-center
              bg-green-600
              border-green-600
              hover:bg-green-500
            "
					>
						Save Changes
					</Button>
				</div>
			)}
		</div>
	);
}

export default QrCode;
