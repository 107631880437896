import { useRef } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Form, ModalProps, ModalType } from "../../../Common";
import { formatPhoneNumber } from "../../Profile";
import { PatchBranchByPartnerInputs } from "../../../../services/types";

type AddUpdateBranchModalProps = {
	onCloseModal: ModalProps["onCloseModal"];
	data: PatchBranchByPartnerInputs;
};

export type NewBranchType = {
	name: string;
	address: string;
	email: string;
	phoneNumber: string;
	main: boolean;
};

const transformPhoneInput = {
	input: (value: string) => {
		if (value !== "") {
			let newValue = value;
			newValue = formatPhoneNumber(newValue)!;
			return newValue ? newValue : value;
		}

		return "";
	},
	output: (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/\D/g, "");

		return value;
	},
};

function AddUpdateBranchModal({
	onCloseModal,
	data,
}: AddUpdateBranchModalProps) {
	const formId = "add-member-form";

	const { branch, branchRole } = data || {};

	const initialValues = useRef({
		name: branch ? branch.name : "",
		address: branch ? branch.address : "",
		email: branch ? branch.email : "",
		phoneNumber: branch ? branch.phoneNumber : "",
		main: branch ? branch.main : false,
	});

	const { handleSubmit, control, formState } = useForm<NewBranchType>({
		mode: "onChange",
		defaultValues: initialValues.current,
	});

	const onSubmit: SubmitHandler<NewBranchType> = async (form) => {
		onCloseModal({
			type: ModalType.ADD_UPDATE_BRANCH,
			data: {
				branch: {
					...form,
					isNewBranch: !branch,
					id: branch?.id,
				},
				branchRole,
			},
		});
	};

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	return (
		<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
			<div
				className="
          text-sm
          max-h-modal-container
          md:text-base
        "
			>
				<Form.Text
					required={true}
					name="name"
					control={control}
					label="Name"
					id="name-input"
					errors={formState.errors}
					rules={{
						required: {
							value: true,
							message: "Name is required.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="name" />
				<Form.Text
					required={true}
					name="address"
					control={control}
					label="Address"
					id="address-input"
					errors={formState.errors}
					rules={{
						required: {
							value: true,
							message: "Address is required.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="address" />

				<Form.Text
					required={true}
					name="email"
					label="Email"
					id="email-input"
					control={control}
					errors={formState.errors}
					rules={{
						pattern: {
							value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
							message: "Enter a valid email.",
						},
						required: { value: true, message: "Email is required." },
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="email" />

				<Form.Text
					required={false}
					control={control}
					name="phoneNumber"
					id="phone-number-input"
					placeholder="17065554433"
					errors={formState.errors}
					transform={transformPhoneInput}
					label="Phone Number (Use country code)"
					rules={{
						maxLength: {
							value: 13,
							message: "Phone Number is too long.",
						},
						pattern: {
							value:
								/\+?\d{1,3}?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/,
							message: "Phone Number is invalid.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="phoneNumber" />

				<Form.Checkbox
					control={control}
					id="main-checkbox"
					label="Main Branch"
					name="main"
				/>
			</div>

			<div
				className="mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse"
			>
				<Button
					form={formId}
					type="submit"
					value="submit"
					id="create-item-submit-button"
					disabled={!formState.isValid}
					className={`
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
				>
					{branch ? "Update Branch" : "Add Branch"}
				</Button>
				<Button
					id="cancel-new-member-modal-button"
					onClick={() => onCloseModal({ type: "", data: null })}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
				>
					Cancel
				</Button>
			</div>
		</Form>
	);
}

export default AddUpdateBranchModal;
