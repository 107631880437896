import { MemberByBranchDTO, UserDTO } from "../../../../services/types";
import { ModalType } from "../../../Common";
import { Delete, Edit } from "../../../Icons";

type MemberProps = {
	index: number;
	member: MemberByBranchDTO;
	handleOnClickMemberOptions: (
		member: MemberByBranchDTO,
		modalType: ModalType
	) => void;
	user: UserDTO;
};

function Member({
	index,
	member,
	handleOnClickMemberOptions,
	user,
}: MemberProps) {
	return (
		<tr key={member.id}>
			<td className="p-3 font-bold text-center">{index + 1}</td>
			<td className="p-3 text-center">
				{member.user.firstName} {member.user.lastName}{" "}
			</td>
			<td className="p-3 text-center">
				{member.isSuperUser ? "SUPER " : ""}
				{member.branchRole.role}
			</td>
			<td className="text-center">
				<div className="flex items-center justify-around">
					{user.member?.isSuperUser && (
						<>
							<button
								className=" bg-green-500
              text-white
              font-semibold
              border-green-500
              hover:bg-green-600
              p-2 
              rounded-md 
              flex 
              items-center
              gap-x-2"
								onClick={() =>
									handleOnClickMemberOptions(
										member,
										ModalType.UPDATE_SUPER_USER
									)
								}
							>
								Super Admin <Edit size={15} color="fill-current" />
							</button>
							<button
								className=" bg-green-500
              text-white
              font-semibold
              border-green-500
              hover:bg-green-600
              p-2 
              rounded-md 
              flex 
              items-center
              gap-x-2"
								onClick={() =>
									handleOnClickMemberOptions(member, ModalType.UPDATE_MEMBER)
								}
							>
								Role <Edit size={15} color="fill-current" />
							</button>
						</>
					)}
					<button
						className=" bg-red-500
            text-white
            font-semibold
            border-red-500
            hover:bg-red-600 
            p-2 
            rounded-md 
            flex 
            items-center
            gap-x-2"
						onClick={() =>
							handleOnClickMemberOptions(member, ModalType.DELETE_MEMBER)
						}
					>
						Delete <Delete size={15} color="fill-current" />
					</button>
				</div>
			</td>
		</tr>
	);
}

export default Member;
