import { useNavigate } from "react-router-dom";
import { useModalContext } from "../../../../providers/ModalProvider";
import { useUserContext } from "../../../../providers/UserProvider";
import { BranchRoleDTO, Role, UserDTO } from "../../../../services/types";
import { ModalType } from "../../../Common";
import { Delete, Edit } from "../../../Icons";
import { getPathToBranch } from "../../../../services/utils";
import { Api } from "../../../../services";

type BranchesListProps = {
	user: UserDTO;
	branches: BranchRoleDTO[];
};

function BranchesList({ user, branches }: BranchesListProps) {
	const modalContext = useModalContext();
	const { handleUpdateMemberBranch } = useUserContext();

	const navigate = useNavigate();

	const handleOnClickBranchesOptions = <T extends object>(
		data: T,
		modalType: ModalType = ModalType.ADD_UPDATE_BRANCH,
		title: string
	) => {
		modalContext.openModalHandler({
			type: modalType,
			data: {
				title,
				data,
			},
		});
	};

	const handleNavigate = (branchRole: BranchRoleDTO) => {
		const { branch, role } = branchRole;
		const { id: branchId, name: branchName } = branch;
		if (user.member?.isSuperUser || role === Role.ADMIN) {
			handleOnClickBranchesOptions(
				{ branchId, branchName },
				ModalType.SELECT_OPTION_TO_MANAGE,
				`¿What would you want to manage?`
			);
		} else {
			handleUpdateMemberBranch(branchId!);
			const url = getPathToBranch({
				branchId: branchId!,
				finalPath: "vehicles",
			});
			navigate(url, {
				relative: "path",
				state: { branchName },
			});
		}
	};

	const handleOnEditClick = async (branchRole: BranchRoleDTO) => {
		const { branch } = await Api.getBranchById(branchRole.branch.id!);
		handleOnClickBranchesOptions(
			{ branch, branchRole },
			ModalType.ADD_UPDATE_BRANCH,
			`Update Branch ${[branchRole.branch.name]}`
		);
	};

	const isNotAdmin = user.member?.branchRole.role !== Role.STAFF;

	return (
		<table className="table w-full text-sm table-fixed">
			<thead className="text-white bg-green-600">
				<tr>
					<th
						style={{ width: "5%" }}
						className="py-3 text-center border-r-white"
					>
						#
					</th>
					<th
						style={{ width: "25%" }}
						className="py-3 text-center border-r-white"
					>
						Branch
					</th>
					<th style={{ width: "15%" }} className="text-center border-r-white">
						Members
					</th>
					<th style={{ width: "15%" }} className="text-center border-r-white">
						Vehicles
					</th>
					<th
						style={{ width: "15%" }}
						className={`text-center ${isNotAdmin ? "border-r-white" : ""}`}
					>
						Role
					</th>
					{isNotAdmin && (
						<th
							style={{ width: `${user.member?.isSuperUser ? "20%" : "10%"}` }}
							className="text-center"
						>
							Actions
						</th>
					)}
				</tr>
			</thead>
			<tbody className="bg-white text-black">
				{branches.map((branchRole, index) => {
					const { branch, role } = branchRole;
					return (
						<tr
							key={branch.id}
							onClick={() => handleNavigate(branchRole)}
							className="cursor-pointer hover:bg-green-50"
						>
							<td className="p-3 font-bold text-center">{index + 1}</td>
							<td className="p-3 text-center">
								{branch.name}
								{branch.main ? " (Main)" : ""}
							</td>
							<td className="p-3 text-center">{branch.members || 0}</td>
							<td className="p-3 text-center">{branch.vehicles || 0}</td>
							<td className="p-3 text-center">{role}</td>
							{isNotAdmin && (
								<td className="text-center">
									<div className="flex items-center justify-around">
										<button
											className=" bg-green-500
                          text-white
                          font-semibold
                          border-green-500
                          hover:bg-green-600
                          p-2 
                          rounded-md 
                          flex 
                          items-center
                          gap-x-2"
											onClick={(e) => {
												e.stopPropagation();
												handleOnEditClick(branchRole);
											}}
										>
											Edit
											<Edit size={15} color="fill-current" />
										</button>

										{user.member?.isSuperUser && (
											<button
												className=" bg-red-500
                        text-white
                        font-semibold
                        border-red-500
                        hover:bg-red-600 
                        p-2 
                        rounded-md 
                        flex 
                        items-center
                        gap-x-2"
												onClick={(e) => {
													e.stopPropagation();
													handleOnClickBranchesOptions(
														branchRole,
														ModalType.DELETE_BRANCH,
														`Delete branch ${branch.name}`
													);
												}}
											>
												Delete <Delete size={15} color="fill-current" />
											</button>
										)}
									</div>
								</td>
							)}
						</tr>
					);
				})}
			</tbody>
		</table>
	);
}

export default BranchesList;
