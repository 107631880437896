import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Form, ModalProps, ModalType } from "../../../Common";
import { Option, VehicleDTO } from "../../../../services/types";
import { useMemo, useRef } from "react";

type AddUpdateVehicleModalProps = {
	onCloseModal: ModalProps["onCloseModal"];
	data: {
		categories: Option[];
		colors: Option[];
		models: Option[];
		qrs: Option[];
		vehicle?: VehicleDTO;
	};
};

export type VehicleType = {
	id?: number;
	brand: string;
	category: Option;
	code: Option;
	color: Option;
	model: Option;
	plate: string;
};

function AddUpdateVehicleModal({
	onCloseModal,
	data,
}: AddUpdateVehicleModalProps) {
	const { categories, colors, models, qrs, vehicle } = data;

	const formId = "add-update-vehicle-form";

	const initialCode = useMemo(() => {
		if (vehicle) {
			return { value: vehicle.qr, label: vehicle.code };
		}
		return qrs[0];
	}, [vehicle, qrs]);

	const initialValues = useRef({
		brand: vehicle ? vehicle.brand : "",
		category:
			categories.find((category) => category.value === vehicle?.category) ||
			categories[0],
		code: initialCode,
		color: colors.find((color) => color.value === vehicle?.color) || colors[0],
		model:
			models.find((model) => model.value === vehicle?.model) || models.at(-1),
		plate: vehicle ? vehicle.plate : "",
	});

	const { handleSubmit, control, formState } = useForm<VehicleType>({
		mode: "onChange",
		defaultValues: initialValues.current,
	});

	const onSubmit: SubmitHandler<VehicleType> = async (form) => {
		onCloseModal({
			type: ModalType.ADD_UPDATE_VEHICLE,
			data: {
				isNewVehicle: !vehicle,
				vehicle: {
					id: vehicle?.id,
					...form,
				},
			},
		});
	};

	const fetchOptionsList = (
		optionsList: Option[],
		search: string,
		callback: (options: Option[]) => void
	) => {
		const options: Option[] = optionsList.map(({ value, label }) => ({
			value,
			label,
		}));

		callback(
			options.filter((option) =>
				option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
			)
		);
	};

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	return (
		<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
			<div
				className="
          text-sm
          max-h-modal-container
          md:text-base
        "
			>
				<div className="w-full flex gap-x-4">
					<Form.Select
						name="code"
						required={true}
						disabled={!!vehicle}
						label="Qr code"
						control={control}
						id="qr-code-input"
						errors={formState.errors}
						loadOptions={(search, callback) =>
							fetchOptionsList(
								vehicle ? ([initialCode] as Option[]) : qrs,
								search,
								callback
							)
						}
						rules={{
							required: {
								value: true,
								message: "Qr code is required.",
							},
						}}
					/>
					<Form.Select
						name="category"
						required={true}
						label="Category"
						control={control}
						id="category-input"
						errors={formState.errors}
						loadOptions={(search, callback) =>
							fetchOptionsList(categories, search, callback)
						}
						rules={{
							required: {
								value: true,
								message: "Category is required.",
							},
						}}
					/>
				</div>
				<div className="w-full flex gap-x-4">
					<div className="w-full flex flex-col">
						<Form.Text
							required={true}
							name="brand"
							control={control}
							label="Brand"
							id="brand-input"
							errors={formState.errors}
							rules={{
								required: {
									value: true,
									message: "Brand is required.",
								},
							}}
						/>
						<Form.ErrorMessage errors={formState.errors} name="brand" />
					</div>
					<div className="w-full flex flex-col">
						<Form.Select
							name="color"
							required={true}
							label="Color"
							control={control}
							id="category-input"
							errors={formState.errors}
							loadOptions={(search, callback) =>
								fetchOptionsList(colors, search, callback)
							}
							rules={{
								required: {
									value: true,
									message: "Color is required.",
								},
							}}
						/>
						<Form.ErrorMessage errors={formState.errors} name="color" />
					</div>
				</div>
				<div className="w-full flex gap-x-4">
					<div className="w-full flex flex-col">
						<Form.Text
							required={true}
							name="plate"
							disabled={!!vehicle}
							control={control}
							label="Plate"
							id="plate-input"
							errors={formState.errors}
							rules={{
								required: {
									value: true,
									message: "Plate is required.",
								},
							}}
						/>
						<Form.ErrorMessage errors={formState.errors} name="plate" />
					</div>
					<div className="w-full flex flex-col">
						<Form.Select
							name="model"
							required={true}
							label="Model"
							control={control}
							id="model-input"
							errors={formState.errors}
							loadOptions={(search, callback) =>
								fetchOptionsList(models, search, callback)
							}
							rules={{
								required: {
									value: true,
									message: "Model is required.",
								},
							}}
						/>
						<Form.ErrorMessage errors={formState.errors} name="model" />
					</div>
				</div>
			</div>

			<div
				className="mt-8
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse"
			>
				<Button
					form={formId}
					type="submit"
					value="submit"
					id="create-vehicle-submit-button"
					disabled={!formState.isValid || !formState.isDirty}
					className={`
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
				>
					{!vehicle ? "Add" : "Update"} vehicle
				</Button>
				<Button
					id="cancel-new-vehicle-modal-button"
					onClick={() => onCloseModal({ type: "", data: null })}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
				>
					Cancel
				</Button>
			</div>
		</Form>
	);
}

export default AddUpdateVehicleModal;
