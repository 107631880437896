function BranchTag({
  branchName,
  width = "w-fit",
}: {
  branchName: string;
  width?: string;
}) {
  return (
    <div className={`text-green-600 flex ${width} justify-center`}>
      <p className="px-2 py-1 border-2 border-green-600 rounded-md text-right text-sm font-semibold w-fit">
        Branch: {branchName}
      </p>
    </div>
  );
}

export default BranchTag;
