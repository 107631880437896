import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import { trackPromise } from "react-promise-tracker";

import {
	Methods,
	CustomHeaderProperties,
	// API INPUTS
	LoginInputs,
	RegisterInputs,
	PatchUserInputs,
	ItemReportInputs,
	PatchQrUserInputs,
	LoginGoogleInputs,
	VerifyEmailInputs,
	LoginFacebookInputs,
	ResetPasswordInputs,
	ResendVerifyEmailInputs,
	ResetPasswordConfirmInputs,
	ManageQrUserItemsInputs,
	// API RESPONSES
	QrAPIResponse,
	UserAPIResponse,
	BasicAPIResponse,
	QrUserAPIResponse,
	UserItemsAPIResponse,
	VerifyAuthAPIResponse,
	CategoriesAPIResponse,
	ColorsAPIResponse,
	ModelsAPIResponse,
	QrListUserAPIResponse,
	BranchesByUserAPIResponse,
	MembersByBranchApiReponse,
	MemberByBranch,
	MemberByBranchUpdatedApiReponse,
	MemberByBranchDTO,
	BranchRole,
	NewMemberByBranch,
	NewMemberByBranchApiReponse,
	BrancheByPartnerAPIResponse,
	Role,
	VehiclesByPartnerApiReponse,
	VehicleDTO,
	Vehicle,
	QrDTO,
	Qr,
	VehicleByBranchApiReponse,
	BranchDTO,
	BranchRoleDTO,
	VehicleReportInputs,
	BranchRoleToUpdate,
	MemberByBranchAPIResponse,
	MemberDTO,
	ChangePasswordInputs,
	BranchByIdAPIResponse,
	PatchBranchByPartnerInputs,
	PostNewBranchRole,
} from "./types";

import StorePersist from "./StorePersist";

type ConfigProps = {
	token: string;
};

class LFApi {
	token: string;
	baseUrl: string;
	apiV1Url: string;
	authUrl: string;
	loginUrl: string;
	signinUrl: string;
	logoutUrl: string;
	verifyUrl: string;
	verifyEmailUrl: string;
	resetPasswordlUrl: string;
	changePasswordlUrl: string;
	resendVerifyEmailUrl: string;
	resetPasswordlConfirmUrl: string;
	partnerUrl: string;
	vehicleUrl: string;

	constructor() {
		this.token = "";
		this.baseUrl = (window as any).SERVER_DATA.REACT_APP_BACKEND_URL;

		this.apiV1Url = `${this.baseUrl}/api/v1`;
		this.authUrl = `${this.apiV1Url}/auth`;

		//Auth endpoints
		this.loginUrl = `${this.authUrl}/login/`;
		this.verifyUrl = `${this.authUrl}/verify/`;
		this.logoutUrl = `${this.authUrl}/logout/`;
		this.signinUrl = `${this.authUrl}/register/`;
		this.verifyEmailUrl = `${this.authUrl}/verify-email/`;
		this.resetPasswordlUrl = `${this.authUrl}/password-reset/`;
		this.resendVerifyEmailUrl = `${this.authUrl}/resend-email/`;
		this.changePasswordlUrl = `${this.authUrl}/password-change/`;
		this.resetPasswordlConfirmUrl = `${this.authUrl}/password-reset-confirm/`;

		//Partner and Vehicle endpoints
		this.partnerUrl = `${this.apiV1Url}/partner/`;
		this.vehicleUrl = `${this.apiV1Url}/vehicle/`;
	}

	configure({ token }: ConfigProps) {
		this.token = token;
	}

	getHeaders(): CustomHeaderProperties {
		return {
			Authorization: `Bearer ${this.token}`,
		} as CustomHeaderProperties;
	}

	privateRequest({
		...props
	}: Omit<AxiosRequestConfig, "method"> & { method: Methods }) {
		return axios.request({
			...props,
			headers: this.getHeaders(),
			method: props.method.toString(),
		});
	}

	// PRIVATE REQUESTS

	async verify(): Promise<VerifyAuthAPIResponse> {
		try {
			await this.privateRequest({
				method: Methods.OPTIONS,
				url: this.verifyUrl,
			});
			return { ok: true, isAuthenticated: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async getUser(): Promise<UserAPIResponse> {
		const userRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.authUrl}/user/`,
			})
		);

		try {
			const { data } = await userRequest;
			return { user: data };
		} catch (error) {
			throw error;
		}
	}

	async patchUser(patchUserInputs: PatchUserInputs): Promise<UserAPIResponse> {
		const qrUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				data: patchUserInputs,
				method: Methods.PATCH,
				url: `${this.authUrl}/user/`,
			})
		);

		try {
			const { data } = await qrUserRequest;

			return { ok: data.ok, user: data.user };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async patchQrUser({ code, user }: PatchQrUserInputs): Promise<QrAPIResponse> {
		const qrUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				data: { user },
				method: Methods.PATCH,
				url: `${this.apiV1Url}/qr/${code}/`,
			})
		);

		try {
			const { data } = await qrUserRequest;

			return { ok: data.ok, qr: data.qr };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getQrListUser(): Promise<QrListUserAPIResponse> {
		const qrUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.apiV1Url}/qr/list/`,
			})
		);

		try {
			const { data } = await qrUserRequest;

			return { ok: true, qrs: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getQrUser(code: string): Promise<QrUserAPIResponse> {
		const qrUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.apiV1Url}/qr/${code}/items/`,
			})
		);

		try {
			const { data } = await qrUserRequest;

			return { ok: true, qr: data.qr };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async postQrUserItems({
		items,
		qrCode,
	}: ManageQrUserItemsInputs): Promise<UserItemsAPIResponse> {
		const createItemRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				data: { items },
				method: Methods.POST,
				url: `${this.apiV1Url}/item/${qrCode}/`,
			})
		);

		try {
			const { data } = await createItemRequest;

			return { ok: data.ok, items: data.items };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async deleteQrUserItems({
		items,
		qrCode,
	}: ManageQrUserItemsInputs): Promise<UserItemsAPIResponse> {
		const createItemRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.DELETE,
				url: `${this.apiV1Url}/item/${qrCode}/`,
				data: { items: items.map((item) => item.id) },
			})
		);

		try {
			const { data } = await createItemRequest;

			return { ok: data.ok, items: data.items };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	// PARTNER REQUESTS
	async getBranchesByUser(
		memberId: number
	): Promise<BranchesByUserAPIResponse> {
		const branchesByUserRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.partnerUrl}member/${memberId}/branch-roles/`,
			})
		);

		try {
			const { data } = await branchesByUserRequest;

			const branchRoles: BranchRoleDTO[] = data.branch_roles
				.filter(({ branch }: BranchRole) => branch.active)
				.map(({ id, branch, role }: BranchRole) => ({
					id,
					branch: {
						...branch,
						phoneNumber: branch.phone_number,
					},
					role,
				}));

			return { ok: true, branchRoles };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getBranchById(branchId: number): Promise<BranchByIdAPIResponse> {
		const branchByIdRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.partnerUrl}branch/${branchId}/`,
			})
		);

		try {
			const { data } = await branchByIdRequest;

			return { ok: true, branch: { ...data, phoneNumber: data.phone_number } };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getMembersByPartner(
		branchId: number
	): Promise<MembersByBranchApiReponse> {
		const membersByPartnerRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.partnerUrl}members?exclude_branch=${branchId}`,
			})
		);

		try {
			const { data } = await membersByPartnerRequest;

			const membersByBrachDTO: MemberByBranchDTO[] = data.results.map(
				(member: MemberByBranch) => ({
					id: member.id,
					user: {
						email: member.user.email,
						firstName: member.user.first_name,
						lastName: member.user.last_name,
					},
					branchRole: member.branch_role,
					isSuperUser: member.is_superuser,
				})
			);
			return { ok: true, members: membersByBrachDTO, count: data.count };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getMembersByBranch(
		branchId: number
	): Promise<MembersByBranchApiReponse> {
		const membersByBranchRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.partnerUrl}members?branch=${branchId}`,
			})
		);

		try {
			const { data } = await membersByBranchRequest;

			const membersByBrachDTO: MemberByBranchDTO[] = data.results.map(
				(member: MemberByBranch) => ({
					id: member.id,
					user: {
						email: member.user.email,
						firstName: member.user.first_name,
						lastName: member.user.last_name,
					},
					branchRole: member.branch_role,
					isSuperUser: member.is_superuser,
				})
			);
			return { ok: true, members: membersByBrachDTO, count: data.count };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async updateMemberByBranch(
		memberId: number,
		branch: number
	): Promise<MemberByBranchAPIResponse> {
		const updatedMemberByBranchRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.PATCH,
				url: `${this.partnerUrl}member/${memberId}/`,
				data: { branch },
			})
		);

		try {
			const { data } = await updatedMemberByBranchRequest;

			const memberByBrachDTO: MemberDTO = {
				id: data.id,
				branchRole: data.branch_role,
				isSuperUser: data.is_superuser,
				partner: data.partner,
			};
			return { ok: true, member: memberByBrachDTO };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}
	async deleteMemberByBranch(memberId: number): Promise<BasicAPIResponse> {
		const deletedMemberByBranchRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.DELETE,
				url: `${this.partnerUrl}member/${memberId}`,
			})
		);

		try {
			const { data } = await deletedMemberByBranchRequest;
			return { ok: true, msg: data.detail };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async UpdateMemberBranchRole(
		branchRole: BranchRole
	): Promise<MemberByBranchUpdatedApiReponse> {
		const memberByBranchUpdatedRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.PATCH,
				url: `${this.partnerUrl}branch-role/${branchRole.id}/`,
				data: {
					branch: branchRole.branch,
					role: BranchRoleToUpdate[
						branchRole.role.toLocaleUpperCase() as keyof typeof BranchRoleToUpdate
					],
				},
			})
		);

		try {
			const { data } = await memberByBranchUpdatedRequest;
			return { ok: true, branchRoleUpdated: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async UpdateSuperUserState(
		branchRole: BranchRole,
		isSuperUser: boolean
	): Promise<MemberByBranchUpdatedApiReponse> {
		const superUserUpdatedRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.PATCH,
				url: `${this.partnerUrl}branch-role/${branchRole.id}/`,
				data: {
					role: "ADMIN",
					is_superuser: isSuperUser,
				},
			})
		);

		try {
			const { data } = await superUserUpdatedRequest;
			return { ok: true, branchRoleUpdated: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async postNewBranchRole({
		branch,
		member,
	}: PostNewBranchRole): Promise<NewMemberByBranchApiReponse> {
		const newBranchRoleRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.POST,
				url: `${this.partnerUrl}branch-role/`,
				data: {
					branch,
					member: member.id,
					role: member.branchRole,
					is_superuser: member.isSuperUser,
				},
			})
		);

		try {
			const { data } = await newBranchRoleRequest;

			const memberByBrachDTO: MemberByBranchDTO = {
				id: member.id,
				user: member.user,
				branchRole: data,
				isSuperUser: member.isSuperUser,
			};
			return { ok: true, memberByBranch: memberByBrachDTO };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async postNewMemberByBranch(
		member: NewMemberByBranch
	): Promise<NewMemberByBranchApiReponse> {
		const { email, branch, branchRole, isSuperUser, firstName, lastName } =
			member;
		const newMemberByBranchRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.POST,
				url: `${this.partnerUrl}member/`,
				data: {
					email,
					branch,
					branch_role: branchRole,
					is_superuser: isSuperUser,
					first_name: firstName,
					last_name: lastName,
				},
			})
		);

		try {
			const { data } = await newMemberByBranchRequest;

			const memberByBrachDTO: MemberByBranchDTO = {
				id: data.id,
				user: {
					email: data.user.email,
					firstName: data.user.first_name,
					lastName: data.user.last_name,
				},
				branchRole: data.branch_role,
				isSuperUser: data.is_superuser,
			};
			return { ok: true, memberByBranch: memberByBrachDTO };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}
	async AddBranchByPartner({
		email,
		name,
		address,
		phoneNumber,
		main,
	}: BranchDTO): Promise<BrancheByPartnerAPIResponse> {
		const newBranchByPartnerRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.POST,
				url: `${this.partnerUrl}branch/`,
				data: {
					email,
					name,
					address,
					phone_number: phoneNumber,
					main,
					active: true,
				},
			})
		);

		try {
			const { data } = await newBranchByPartnerRequest;

			const branchByPartnerDTO: BranchRoleDTO = {
				id: data.id,
				branch: data,
				role: Role.ADMIN,
			};

			return { ok: true, branchRole: branchByPartnerDTO };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getCategoriesByVehicle(): Promise<CategoriesAPIResponse> {
		const allCategoriesByVehicleRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.vehicleUrl}categories/`,
			})
		);

		try {
			const { data } = await allCategoriesByVehicleRequest;

			return { ok: true, categories: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async getColorsByVehicle(): Promise<ColorsAPIResponse> {
		const allColorsByVehicleRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.vehicleUrl}colors/`,
			})
		);

		try {
			const { data } = await allColorsByVehicleRequest;

			return { ok: true, colors: data.colors };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async getModelsByVehicle(): Promise<ModelsAPIResponse> {
		const allModelsByVehicleRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.vehicleUrl}years/`,
			})
		);

		try {
			const { data } = await allModelsByVehicleRequest;

			return { ok: true, models: data.years };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async getAllVehiclesByBranch(
		branchId: number
	): Promise<VehiclesByPartnerApiReponse> {
		const allVehiclesByPartnerRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.partnerUrl}vehicles?branch=${branchId}`,
			})
		);

		try {
			const { data } = await allVehiclesByPartnerRequest;

			const vehiclesByPartner: VehicleDTO[] = data.results.map(
				(vehicle: Vehicle) => {
					const {
						id,
						branch,
						category,
						brand,
						qr,
						code,
						color,
						model,
						plate,
						booked,
						booked_by,
						booked_email,
						booked_phone_number,
					} = vehicle;

					const vehicleDTO: VehicleDTO = {
						id,
						category,
						brand,
						branch,
						qr,
						code,
						color,
						model,
						plate,
						booked,
						bookedBy: booked_by,
						bookedEmail: booked_email,
						bookedPhoneNumber: booked_phone_number,
					};

					return vehicleDTO;
				}
			);

			return { ok: true, vehicles: vehiclesByPartner, count: data.count };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async getQRsByPartner(): Promise<QrListUserAPIResponse> {
		const allQRsByCodeRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.partnerUrl}qr/list/`,
			})
		);

		try {
			const { data } = await allQRsByCodeRequest;

			const qrsBypartner: QrDTO[] = data.results.map((qr: Qr) => ({
				...qr,
				createdDate: qr.created_date,
				hasVehicle: qr.has_vehicle,
				assignedDate: qr.assigned_date,
				adLogoPath: qr.ad_logo_path,
				itemsLimit: qr.items_limit,
			}));

			return { ok: true, qrs: qrsBypartner, count: data.count };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async AddVehicleByBranch(
		vehicle: VehicleDTO
	): Promise<VehicleByBranchApiReponse> {
		const newVehicle: Vehicle = {
			...vehicle,
			booked_by: vehicle.bookedBy,
			booked_email: vehicle.bookedEmail,
			booked_phone_number: vehicle.bookedPhoneNumber,
		};
		const addVehicleByBranchRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.POST,
				url: `${this.vehicleUrl}`,
				data: newVehicle,
			})
		);

		try {
			const { data } = await addVehicleByBranchRequest;

			const vehicleCreated: VehicleDTO = {
				...data,
				bookedBy: data.booked_by,
				bookedEmail: data.booked_email,
				bookedPhoneNumber: data.booked_phone_number,
			};

			return { ok: true, vehicle: vehicleCreated };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const errorData = error.response?.data;
				const errorMessages = Array.isArray(errorData)
					? errorData.join(", ")
					: Object.entries(errorData)
							.map(
								([key, value]) =>
									`${key}: ${Array.isArray(value) ? value.join(", ") : value}`
							)
							.join("; ");
				return { error: errorMessages };
			} else throw error;
		}
	}
	async PatchVehicleByBranch(
		vehicle: VehicleDTO
	): Promise<VehicleByBranchApiReponse> {
		const vehicleToPatch: Vehicle = {
			...vehicle,
			booked_by: vehicle.bookedBy,
			booked_email: vehicle.bookedEmail,
			booked_phone_number: vehicle.bookedPhoneNumber,
		};
		const patchedVehicleByBranchRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.PATCH,
				url: `${this.vehicleUrl}${vehicle.id}/`,
				data: vehicleToPatch,
			})
		);

		try {
			const { data } = await patchedVehicleByBranchRequest;

			const { booked_by, booked_email, booked_phone_number, ...restData } =
				data;

			const patchedVehicle: VehicleDTO = {
				...restData,
				bookedBy: booked_by,
				bookedEmail: booked_email,
				bookedPhoneNumber: booked_phone_number,
			};

			return { ok: true, vehicle: patchedVehicle };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const errorData = error.response?.data;
				const errorMessages = Array.isArray(errorData)
					? errorData.join(", ")
					: Object.entries(errorData)
							.map(
								([key, value]) =>
									`${key}: ${Array.isArray(value) ? value.join(", ") : value}`
							)
							.join("; ");
				return { error: errorMessages };
			} else throw error;
		}
	}

	async deleteVehicleByBranch(vehicleId: number): Promise<BasicAPIResponse> {
		const deletedVehicleByBranchRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.DELETE,
				url: `${this.vehicleUrl}${vehicleId}/`,
			})
		);

		try {
			const { data } = await deletedVehicleByBranchRequest;
			return { ok: true, msg: data.detail };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async patchBranchByPartner({
		branch,
		branchRole,
	}: PatchBranchByPartnerInputs): Promise<BrancheByPartnerAPIResponse> {
		const { email, id, name, main, address, phoneNumber } = branch;
		const newBranchByPartnerRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.PATCH,
				url: `${this.partnerUrl}branch/${id}/`,
				data: {
					email,
					name,
					address,
					phone_number: phoneNumber,
					main,
				},
			})
		);

		try {
			const { data } = await newBranchByPartnerRequest;

			const branchByPartnerDTO: BranchRoleDTO = {
				id: branchRole.id,
				branch: {
					...data,
					phoneNumber: data.phone_number,
				},
				role: branchRole.role,
			};

			return { ok: true, branchRole: branchByPartnerDTO };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async deleteBranchByPartner(branch: BranchDTO): Promise<BasicAPIResponse> {
		const deletedBranchByPartnerRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.PATCH,
				url: `${this.partnerUrl}branch/${branch.id}/`,
				data: {
					active: false,
				},
			})
		);

		try {
			const { data } = await deletedBranchByPartnerRequest;
			return { ok: true, msg: data.detail };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getQrVehicle(qrCode: string): Promise<VehicleByBranchApiReponse> {
		const qrVehicleRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				method: Methods.GET,
				url: `${this.apiV1Url}/qr/${qrCode}/vehicle/`,
			})
		);
		try {
			const { data } = await qrVehicleRequest;

			const { booked_by, booked_email, booked_phone_number, ...restData } =
				data.qr.vehicle;

			const qrVehicle: VehicleDTO = {
				...restData,
				bookedBy: booked_by,
				bookedEmail: booked_email,
				bookedPhoneNumber: booked_phone_number,
			};

			return { ok: true, vehicle: qrVehicle };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async postVehicleReport(
		vehicleReportObject: VehicleReportInputs
	): Promise<BasicAPIResponse> {
		const vehicleReportRequest = trackPromise<AxiosResponse>(
			axios.post(`${this.vehicleUrl}report/`, {
				report: vehicleReportObject,
			})
		);

		try {
			const { data } = await vehicleReportRequest;

			return { ok: data.ok };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	// PUBLIC REQUESTS

	async register(registerObject: RegisterInputs): Promise<BasicAPIResponse> {
		const registerRequest = trackPromise<AxiosResponse>(
			axios.post(this.signinUrl, registerObject)
		);

		try {
			await registerRequest;
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { email, password1, password2, non_field_errors } =
					error.response?.data;
				return {
					error: email || password1 || password2 || non_field_errors[0],
				};
			} else throw error;
		}
	}

	async verifyEmail(
		verifyObject: VerifyEmailInputs
	): Promise<BasicAPIResponse> {
		try {
			await axios.post(this.verifyEmailUrl, verifyObject);
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async resendVerifyEmail(
		resendObject: ResendVerifyEmailInputs
	): Promise<BasicAPIResponse> {
		const resendVerifyEmailRequest = trackPromise<AxiosResponse>(
			axios.post(this.resendVerifyEmailUrl, resendObject)
		);

		try {
			await resendVerifyEmailRequest;
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { email, non_field_errors } = error.response?.data;
				return { error: email || non_field_errors[0] };
			} else throw error;
		}
	}

	async login(loginObject: LoginInputs): Promise<UserAPIResponse> {
		const loginRequest = trackPromise<AxiosResponse>(
			axios.post(this.loginUrl, loginObject)
		);

		try {
			const loginResponse = await loginRequest;
			const { access_token } = loginResponse.data;

			this.configure({ token: access_token });
			const { user } = await this.getUser();

			StorePersist.saveState(
				{ user, token: access_token },
				StorePersist.Items.AUTH_STATE
			);

			return { ok: true, user: user };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { email, non_field_errors } = error.response?.data;
				return { error: email || non_field_errors[0] };
			} else throw error;
		}
	}

	async loginGoogle(
		loginGoogleObject: LoginGoogleInputs
	): Promise<BasicAPIResponse> {
		const loginGoogleRequest = trackPromise<AxiosResponse>(
			axios.post(`${this.authUrl}/google/`, loginGoogleObject)
		);

		try {
			const loginGoogleResponse = await loginGoogleRequest;
			const { access_token } = loginGoogleResponse.data;

			this.configure({ token: access_token });
			const { user } = await this.getUser();

			StorePersist.saveState(
				{ user, token: access_token },
				StorePersist.Items.AUTH_STATE
			);

			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { response } = error;
				const errorMsg = response?.data.non_field_errors
					? response?.data.non_field_errors[0]
					: null;

				return { error: `Google: ${errorMsg || response?.statusText}` };
			} else throw error;
		}
	}

	async loginFacebook(
		loginFacebookObject: LoginFacebookInputs
	): Promise<BasicAPIResponse> {
		const loginFacebookRequest = trackPromise<AxiosResponse>(
			axios.post(`${this.authUrl}/facebook/`, {
				access_token: loginFacebookObject.accessToken,
			})
		);

		try {
			const loginFacebookResponse = await loginFacebookRequest;
			const { access_token } = loginFacebookResponse.data;

			this.configure({ token: access_token });
			const { user } = await this.getUser();

			StorePersist.saveState(
				{ user, token: access_token },
				StorePersist.Items.AUTH_STATE
			);

			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { response } = error;
				const errorMsg = response?.data.non_field_errors
					? response?.data.non_field_errors[0]
					: null;

				return { error: `Facebook: ${errorMsg || response?.statusText}` };
			} else throw error;
		}
	}

	async resetPassword(
		resetPasswordObject: ResetPasswordInputs
	): Promise<BasicAPIResponse> {
		const resetPasswordRequest = trackPromise<AxiosResponse>(
			axios.post(this.resetPasswordlUrl, resetPasswordObject)
		);

		try {
			await resetPasswordRequest;
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { email, non_field_errors } = error.response?.data;
				return { error: email || non_field_errors[0] };
			} else throw error;
		}
	}

	async changePassword(
		changePasswordObject: ChangePasswordInputs
	): Promise<BasicAPIResponse> {
		const resetPasswordRequest = trackPromise<AxiosResponse>(
			this.privateRequest({
				data: changePasswordObject,
				method: Methods.POST,
				url: this.changePasswordlUrl,
			})
		);

		try {
			await resetPasswordRequest;
			return { ok: true };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { old_password, non_field_errors } = error.response?.data;
				return { error: old_password || non_field_errors[0] };
			} else throw error;
		}
	}

	async resetPasswordConfirm(
		resetPasswordConfirmObject: ResetPasswordConfirmInputs
	): Promise<BasicAPIResponse> {
		const resetPasswordConfirmRequest = trackPromise<AxiosResponse>(
			axios.post(this.resetPasswordlConfirmUrl, resetPasswordConfirmObject)
		);

		try {
			const { data } = await resetPasswordConfirmRequest;

			return { ok: true, msg: data.detail };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { uid, token, new_password1, new_password2, non_field_errors } =
					error.response?.data;

				const processUid = uid ? `ID: ${uid}` : null;
				const processToken = token ? `Token: ${token}` : null;
				return {
					error:
						processUid ||
						processToken ||
						new_password1 ||
						new_password2 ||
						non_field_errors[0],
				};
			} else throw error;
		}
	}

	async logout(): Promise<boolean> {
		try {
			await axios.get(this.logoutUrl);
			return true;
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				return false;
			} else throw error;
		}
	}

	async getQr(code: string): Promise<QrAPIResponse> {
		const qrRequest = trackPromise<AxiosResponse>(
			axios.get(`${this.apiV1Url}/qr/${code}/`)
		);

		try {
			const { data } = await qrRequest;

			return { ok: true, qr: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getQrItems(qrCode: string): Promise<UserItemsAPIResponse> {
		const userItemsRequest = trackPromise<AxiosResponse>(
			axios.get(`${this.apiV1Url}/item/${qrCode}/`)
		);

		try {
			const { data } = await userItemsRequest;

			return { ok: true, items: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}

	async getItemCategories(): Promise<CategoriesAPIResponse> {
		const allItemCategoriesRequest = axios.get(
			`${this.apiV1Url}/item/categories/`
		);

		try {
			const { data } = await allItemCategoriesRequest;

			return { ok: true, categories: data };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail } = error.response?.data;
				return { error: detail };
			} else throw error;
		}
	}

	async postItemReport(
		itemReportObject: ItemReportInputs
	): Promise<BasicAPIResponse> {
		const itemReportRequest = trackPromise<AxiosResponse>(
			axios.post(`${this.apiV1Url}/item/report/`, {
				report: {
					...itemReportObject,
					item: itemReportObject.item?.value,
				},
			})
		);

		try {
			const { data } = await itemReportRequest;

			return { ok: data.ok };
		} catch (e) {
			const error = e as Error | AxiosError;
			if (axios.isAxiosError(error)) {
				const { detail, message } = error.response?.data;
				const defaultMessage = message ?? error.message;
				return { error: detail ?? defaultMessage };
			} else throw error;
		}
	}
}

const Api = new LFApi();

export default Api;
