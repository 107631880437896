function EmptyList({
  parent,
  child,
  complementary = "",
}: {
  parent: string;
  child: string;
  complementary?: string;
}) {
  return (
    <div className="h-52 w-full flex flex-col items-center justify-center">
      <p className="text-center text-gray-500">
        No {child} found for this {parent}.
      </p>
      {complementary && (
        <p className="text-center text-gray-500">{complementary}</p>
      )}
    </div>
  );
}

export default EmptyList;
