import { ChangeEvent, MouseEvent, useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useGoogleLogin } from "@react-oauth/google";
import { FacebookLoginClient } from "@greatsumini/react-facebook-login";

import { Checkbox } from "../Common/Inputs";
import { Form, Link, Button, Divider } from "../Common";

import { Brand } from "../Icons";

import { Api, GetFlag, StorePersist } from "../../services";
import { User } from "../../services/types";

export type LoginInputs = {
	email: string;
	password: string;
};

const FACEBOOK_LOGIN_FLAG = GetFlag("FACEBOOK_LOGIN_FLAG");

function Login() {
	const formId = "login-form";

	const navigate = useNavigate();
	const { state } = useLocation();

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const { watch, handleSubmit, control, formState } = useForm<LoginInputs>({
		mode: "onChange",
		defaultValues: {
			email: state?.email || "",
			password: "",
		},
	});

	const watchForm = watch(["email", "password"]);

	const clearNextRoute = () =>
		StorePersist.removeState(StorePersist.Items.REDIRECT_STATE);

	const getNextRoute = () => {
		const { next: nextLoaded } = StorePersist.loadState(
			StorePersist.Items.REDIRECT_STATE
		);

		return decodeURIComponent(state?.next || nextLoaded);
	};

	const getUrlBasedOnMemberValue = (user: User) => {
		const { member } = user;
		if (!member) return "/app/qr";
		return "/app/partner";
	};

	const onSubmit: SubmitHandler<LoginInputs> = async (form) => {
		setError({ msg: "" });
		try {
			const next = getNextRoute();
			const { ok, error, user } = await Api.login(form);

			if (ok && user) {
				clearNextRoute();
				navigate(next ? next : getUrlBasedOnMemberValue(user), {});
			} else {
				if (error === "E-mail is not verified.")
					navigate("/auth/verify", {
						state: { ...(state || {}), email: form.email },
					});
				else setError({ msg: error! });
			}
		} catch (e) {
			const error = e as Error;
			setError({ msg: error.message, generic: true });
		}
	};

	const handleLoginGoogle = useGoogleLogin({
		flow: "auth-code",
		onSuccess: async ({ code }) => {
			const next = getNextRoute();
			const { ok, error } = await Api.loginGoogle({ code });

			if (ok) {
				clearNextRoute();
				navigate(next ? next : "/app", {});
			} else setError({ msg: error! });
		},
		onError: ({ error_description }) =>
			setError({ msg: error_description ?? "", generic: true }),
	});

	const handleLoginFacebook = () =>
		FacebookLoginClient.login(
			({ authResponse }) => {
				const handleLoginFacebookApi = async () => {
					if (!authResponse) return;

					const next = getNextRoute();

					const { ok, error } = await Api.loginFacebook({
						accessToken: authResponse.accessToken,
					});

					if (ok) {
						clearNextRoute();
						navigate(next ? next : "/app", {});
					} else setError({ msg: error! });
				};

				handleLoginFacebookApi();
			},
			{
				scope: "public_profile, email",
			}
		);

	const handleShowPassword = useCallback(
		(event: ChangeEvent<HTMLInputElement>) =>
			setShowPassword(!event.target.defaultChecked),
		[]
	);

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<h2
					className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
				>
					Login to your account
				</h2>
			</div>

			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<h3 className="mt-5 mb-1 text-center tracking-tight text-gray-500">
					Not a member yet?
				</h3>

				<Button
					padding={false}
					onClick={() =>
						navigate("/auth/register", {
							state: { ...(state || {}), email: watchForm[0] },
						})
					}
					className="
            flex
            py-1
            w-full
            text-sm
            border-2
            leading-6
            shadow-sm
            text-white
            rounded-md
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
          "
				>
					Register
				</Button>
			</div>

			<div className="mt-5 sm:mx-auto sm:w-full sm:max-w-sm">
				<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
					<Form.Text
						name="email"
						label="email"
						id="email-input"
						required={true}
						control={control}
						errors={formState.errors}
						rules={{
							pattern: {
								value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
								message: "Enter a valid email.",
							},
							required: { value: true, message: "Email is required." },
						}}
					/>
					<Form.ErrorMessage errors={formState.errors} name="email" />

					<Form.Text
						required={true}
						name="password"
						label="password"
						control={control}
						id="password-input"
						errors={formState.errors}
						type={showPassword ? "text" : "password"}
						rules={{
							required: { value: true, message: "Password is required." },
							minLength: {
								value: 8,
								message: "Password should be at least 8 characters",
							},
						}}
					/>
					<Form.ErrorMessage errors={formState.errors} name="password" />

					<div>
						<div className="flex flex-row items-center justify-between">
							<Checkbox
								label="Show password"
								checked={showPassword}
								id="show-password-checkbox"
								onChange={handleShowPassword}
							/>
							<Link
								type="button"
								text="Forgot password?"
								className="inline-block"
								onClick={(event?: MouseEvent<HTMLButtonElement>) => {
									event?.preventDefault();
									navigate("/auth/reset-password", {
										state: { ...(state || {}), email: watchForm[0] },
									});
								}}
							/>
						</div>
					</div>

					<div className="mt-1 text-sm text-center font-semibold text-red-600">
						{error && (
							<>
								{error.generic && (
									<p>Something went wrong, please try again later!</p>
								)}
								<p>{error.msg}</p>
							</>
						)}
					</div>

					<Button
						form={formId}
						type="submit"
						value="submit"
						id="login-submit-button"
						disabled={!formState.isValid}
						className={`
              flex
              mt-10
              w-full
              border-2
              leading-6
              shadow-sm
              rounded-md
              text-white
              font-semibold
              justify-center
              bg-green-600
              border-green-600
              hover:bg-green-500
              ${disabledSubmitButton}
            `}
					>
						Log In
					</Button>
				</Form>

				<Divider className="text-green-600" />

				<Button
					onClick={() => {
						setError({ msg: "" });
						handleLoginGoogle();
					}}
					className="
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-red-500
            text-white
            font-semibold
            border-red-500
            hover:bg-red-400
          "
				>
					Google
				</Button>
				{FACEBOOK_LOGIN_FLAG && (
					<Button
						onClick={() => {
							setError({ msg: "" });
							handleLoginFacebook();
						}}
						className="
              mt-5
              w-full
              border-2
              leading-6
              shadow-sm
              rounded-md
              bg-blue-500
              text-white
              font-semibold
              border-blue-500
              hover:bg-blue-400
            "
					>
						Facebook
					</Button>
				)}
			</div>
		</div>
	);
}

export default Login;
