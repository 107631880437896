import { useLocation } from "react-router-dom";

import { Brand } from "../../Icons";

import VehicleReport from "../../Report/VehicleReport";
import ItemReport from "../../Report/ItemReport";

type ItemOption = {
	value: number;
	label: string;
};

export type ItemReportInputs = {
	user: number;
	item: ItemOption | null;
	phone_number?: string;
	email?: string;
	message?: string;
};

function QrCodeReport() {
	const { state } = useLocation();

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-sm">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<h2
					className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
				>
					Report Lost {state.partner ? "car keys" : "Item"}
				</h2>
			</div>

			{state.partner ? <VehicleReport /> : <ItemReport />}
		</div>
	);
}

export default QrCodeReport;
