import { Button, ModalProps, ModalType } from "../../../Common";
import { BranchRoleDTO } from "../../../../services/types";

type DeleteBranchModalProps = {
  data: BranchRoleDTO;
  onCloseModal: ModalProps["onCloseModal"];
};

function DeleteBranchModal({
  data: branchRole,
  onCloseModal,
}: DeleteBranchModalProps) {
  const handleOnClickDeleteBranch = async () => {
    if (!branchRole) return;

    onCloseModal({ type: ModalType.DELETE_BRANCH, data: branchRole });
  };

  return (
    <div className="flex flex-1 flex-col">
      <p
        className="
          text-xl
          font-bold
          leading-9
          text-center
          text-black
          tracking-tight
        "
      >
        <span>Are you sure, you want to delete this branch from partner?</span>
      </p>

      <div
        className="
          mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse
      "
      >
        <Button
          id="delete-branch-modal-button"
          onClick={handleOnClickDeleteBranch}
          className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
          "
        >
          Delete Branch
        </Button>
        <Button
          id="cancel-qr-modal-button"
          onClick={() => onCloseModal({ type: "", data: null })}
          className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
        >
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default DeleteBranchModal;
