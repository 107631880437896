import { useUserContext } from "../../../../providers/UserProvider";
import { Role, VehicleDTO } from "../../../../services/types";
import { Button, ModalType } from "../../../Common";
import { Check, Close, Delete, Edit } from "../../../Icons";

type VehicleProps = {
	index: number;
	vehicle: VehicleDTO;
	handleOnClickVehicleOptions: (
		vehicle: VehicleDTO,
		modalType: ModalType,
		title: string
	) => void;
	categoriesByVehicle: Record<number, string>;
};

function Vehicle({
	index,
	vehicle,
	handleOnClickVehicleOptions,
	categoriesByVehicle,
}: VehicleProps) {
	const { user } = useUserContext();

	return (
		<tr key={vehicle.id} className={`${vehicle.booked && "bg-green-100"}`}>
			<td className="p-3 font-bold text-center">{index + 1}</td>
			<td className="p-3 text-center">{vehicle.plate}</td>
			<td className="p-3 text-center">{vehicle.brand}</td>
			<td className="p-3 text-center">
				{categoriesByVehicle[vehicle.category]}
			</td>
			<td className="p-3 text-center">{vehicle.model}</td>
			<td className="p-3 text-center">{vehicle.code}</td>
			<td className={`p-4 text-center flex justify-center`}>
				{vehicle.booked ? (
					<Check size={20} color="fill-green-600" />
				) : (
					<Close width={20} height={20} color="stroke-red-600" />
				)}
			</td>
			<td className="text-center">
				<div className="flex items-center justify-around">
					<Button
						className=" bg-green-500
              text-white
              font-semibold
              border-green-500
              hover:bg-green-600
              p-2 
              rounded-md 
              flex 
              items-center
              gap-x-2"
						onClick={() =>
							handleOnClickVehicleOptions(
								vehicle,
								ModalType.RENT_HIRE_VEHICLE,
								`${vehicle.booked ? "Hire" : "Rent"} ${vehicle.brand} - [${
									vehicle.plate
								}]`
							)
						}
					>
						{vehicle.booked ? "Hire" : "Rent"}{" "}
						<Edit size={15} color="fill-current" />
					</Button>
					{user.member?.branchRole.role === Role.ADMIN && (
						<Button
							className="
                  bg-green-500
                  text-white
                  font-semibold
                  border-green-500
                  hover:bg-green-600
                  p-2 
                  rounded-md 
                  flex 
                  items-center
                  gap-x-2
                "
							onClick={() =>
								handleOnClickVehicleOptions(
									vehicle,
									ModalType.ADD_UPDATE_VEHICLE,
									`Edit ${vehicle.brand} - [${vehicle.plate}]`
								)
							}
						>
							Edit <Edit size={15} color="fill-current" />
						</Button>
					)}
					{user.member?.isSuperUser && (
						<Button
							className="
                  bg-red-500
                  text-white
                  font-semibold
                  border-red-500
                  hover:bg-red-600 
                  p-2 
                  rounded-md 
                  flex 
                  items-center
                  gap-x-2
                "
							onClick={() =>
								handleOnClickVehicleOptions(
									vehicle,
									ModalType.DELETE_VEHICLE,
									`Delete ${vehicle.brand} - [${vehicle.plate}]`
								)
							}
						>
							Delete <Delete size={15} color="fill-current" />
						</Button>
					)}
				</div>
			</td>
		</tr>
	);
}

export default Vehicle;
