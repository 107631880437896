import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Brand, Check, Close } from "../../Icons";

import { Api } from "../../../services";
import { QrDTO } from "../../../services/types";

function QrCodeList() {
  const navigate = useNavigate();

  const apiCall = useRef<boolean>(false);
  const [qrListUser, setQrListUser] = useState<QrDTO[]>([]);
  const [error, setError] = useState<{ msg: string; generic?: boolean }>({
    msg: "",
  });

  useEffect(() => {
    const getQrList = async () => {
      try {
        apiCall.current = true;
        const { ok, qrs, error } = await Api.getQrListUser();

        if (ok && qrs) {
          setQrListUser(qrs);
        } else setError({ msg: error! });
      } catch (e) {
        const error = e as Error;
        setError({ msg: error.message, generic: true });
      }
    };

    if (!apiCall.current) getQrList();
  }, []);

  return (
    <div
      className="
        px-6
        flex
        py-12
        flex-col
        min-h-max
        justify-center
        lg:px-8
      "
    >
      <div className="sm:mx-auto sm:w-full sm:max-w-lg">
        <div className="w-20 mx-auto">
          <Brand size={80} color="fill-green-600" />
        </div>
        <div className="mt-5 flex flex-row items-center justify-center">
          <h2 className="text-black tracking-tight">
            <span className="text-2xl font-bold">QR Code List</span>
          </h2>
        </div>
      </div>

      <div className="mt-1 text-sm text-center font-semibold text-red-600">
        {error.msg !== "" && (
          <>
            {error.generic && (
              <p>Something went wrong, please try again later!</p>
            )}
            <p>{error.msg}</p>
          </>
        )}
      </div>

      <div
        className="
          mt-10
          shadow-md
          rounded-md
          overflow-auto
          sm:w-full
          sm:mx-auto
          sm:max-w-xl
        "
      >
        <table className="table w-full text-sm table-fixed">
          <thead className="text-white bg-green-600">
            <tr>
              <th
                style={{ width: "10%" }}
                className="p-3 text-center border-r-white"
              >
                #
              </th>
              <th
                style={{ width: "70%" }}
                className="p-3 text-left border-r-white"
              >
                QR
              </th>
              <th style={{ width: "20%" }} className="p-3 text-center">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white text-black">
            {qrListUser.map((item, index) => (
              <tr
                key={index}
                onClick={() => navigate(item.code, {})}
                className="cursor-pointer hover:bg-green-50"
              >
                <td className="p-3 font-bold text-center">{index + 1}</td>
                <td className="p-3 text-left">{item.code}</td>
                <td className="p-3">
                  <div
                    className={`
                      flex
                      text-black
                      items-center
                      cursor-pointer
                      justify-center
                      ${item.active ? "text-green-600" : "text-red-600"}
                    `}
                  >
                    {item.active ? (
                      <Check size={20} color="fill-current" />
                    ) : (
                      <Close width={20} height={20} color="fill-current" />
                    )}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default QrCodeList;
