import { useEffect, useRef, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { useNavigate } from "react-router-dom";

import { Api, StorePersist } from "../services";
import { INITIAL_USER, useUserContext } from "../providers/UserProvider";

export function useVerifyAuth() {
	const authRef = useRef<boolean>(false);

	const { handleSetUser } = useUserContext();

	useEffect(() => {
		(async () => {
			if (authRef.current) return;

			authRef.current = true;
			const { user, token } = StorePersist.loadState(
				StorePersist.Items.AUTH_STATE
			);

			if (token) {
				Api.configure({ token });
				const { isAuthenticated } = await trackPromise(Api.verify());

				const excludePaths = ["/auth", "/auth/login", "/auth/register"];

				if (excludePaths.includes(window.location.pathname))
					window.location.href = "/app";

				handleSetUser({
					ready: isAuthenticated ?? false,
					user: isAuthenticated
						? {
								id: user.id,
								email: user.email,
								firstName: user.first_name,
								lastName: user.last_name,
								phoneNumber: user.phone_number,
								phoneNumberAlt: user.phone_number_alt,
								isActive: user.is_active,
								member: {
									...user.member,
									isSuperUser: user.member?.is_superuser,
									branchRole: user.member?.branch_role,
								},
						  }
						: INITIAL_USER,
				});
			}
		})();
	}, [handleSetUser]);
}

export function useAuthentication() {
	const navigate = useNavigate();

	const authRef = useRef<boolean>(false);
	const [ready, setReady] = useState(false);

	const { handleSetUser } = useUserContext();

	useEffect(() => {
		(async () => {
			setReady(false);
			if (authRef.current) return;

			authRef.current = true;
			const { user, token } = StorePersist.loadState(
				StorePersist.Items.AUTH_STATE
			);

			Api.configure({ token });
			const { isAuthenticated } = await Api.verify();

			if (isAuthenticated) {
				setReady(true);
				handleSetUser({
					ready: isAuthenticated,
					user: {
						id: user.id,
						email: user.email,
						firstName: user.first_name,
						lastName: user.last_name,
						phoneNumber: user.phone_number,
						phoneNumberAlt: user.phone_number_alt,
						isActive: user.is_active,
						member: {
							...user.member,
							isSuperUser: user.member?.is_superuser,
							branchRole: user.member?.branch_role,
						},
					},
				});
			} else {
				setReady(false);
				return navigate("/auth/logout", {
					state: {
						next:
							window.location.pathname.length > 0
								? encodeURIComponent(window.location.pathname)
								: null,
					},
				});
			}
			authRef.current = false;
		})();
	}, [navigate, handleSetUser]);

	return { isAuthenticated: ready };
}
