import { VehicleDTO } from "../../../../services/types";
import { ModalType } from "../../../Common";
import Vehicle from "./Vehicle";

type VehiclesListProps = {
	vehicles: VehicleDTO[];
	handleOnClickVehicleOptions: (
		vehicle: VehicleDTO,
		modalType: ModalType,
		title: string
	) => void;
	categoriesByVehicle: Record<number, string>;
};
function VehiclesList({
	vehicles,
	handleOnClickVehicleOptions,
	categoriesByVehicle,
}: VehiclesListProps) {
	return (
		<>
			<table className="table w-full text-sm table-fixed">
				<thead className="text-white bg-green-600">
					<tr>
						<th
							style={{ width: "5%" }}
							className="py-3 text-center border-r-white"
						>
							#
						</th>
						<th style={{ width: "10%" }} className="text-center border-r-white">
							Plate
						</th>
						<th style={{ width: "10%" }} className="text-center border-r-white">
							Brand
						</th>
						<th style={{ width: "10%" }} className="text-center border-r-white">
							Category
						</th>
						<th style={{ width: "10%" }} className="text-center border-r-white">
							Model
						</th>
						<th style={{ width: "15%" }} className="text-center border-r-white">
							QR code
						</th>
						<th style={{ width: "10%" }} className="text-center border-r-white">
							Booked
						</th>
						<th style={{ width: "25%" }} className="text-center border-r-white">
							Actions
						</th>
					</tr>
				</thead>

				<tbody className="mt-8 space-y-4">
					{vehicles.map((vehicle, index) => (
						<Vehicle
							index={index}
							key={vehicle.id}
							vehicle={vehicle}
							handleOnClickVehicleOptions={handleOnClickVehicleOptions}
							categoriesByVehicle={categoriesByVehicle}
						/>
					))}
				</tbody>
			</table>
		</>
	);
}

export default VehiclesList;
