import { ChangeEvent, useCallback, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Form, ModalProps, ModalType } from "../../../Common";
import { Checkbox } from "../../../Common/Inputs";

import { Api } from "../../../../services";

type ChangePasswordModalProps = {
	onCloseModal: ModalProps["onCloseModal"];
};

export type ChangePasswordInputs = {
	old_password: string;
	new_password1: string;
	new_password2: string;
};

function ChangePasswordModal({ onCloseModal }: ChangePasswordModalProps) {
	const formId = "change-password-form";

	const [showPassword, setShowPassword] = useState<boolean>(false);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const { watch, handleSubmit, control, formState } =
		useForm<ChangePasswordInputs>({
			mode: "onChange",
			defaultValues: {
				old_password: "",
				new_password1: "",
				new_password2: "",
			},
		});

	const password1 = watch("new_password1");

	const onSubmit: SubmitHandler<ChangePasswordInputs> = async (form) => {
		try {
			const { ok, error } = await Api.changePassword(form);

			if (ok) {
				onCloseModal({ type: ModalType.CHANGE_PASSWORD, data: null });
			} else setError({ msg: error! });
		} catch (e) {
			const error = e as Error;
			setError({ msg: error.message, generic: true });
		}
	};

	const handleShowPassword = useCallback(
		(event: ChangeEvent<HTMLInputElement>) =>
			setShowPassword(!event.target.defaultChecked),
		[]
	);

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	return (
		<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
			<div
				className="
          text-sm
          overflow-scroll
          max-h-modal-container
          md:text-base
        "
			>
				<Form.Text
					required={true}
					name="old_password"
					label="Password"
					control={control}
					id="old-password-input"
					errors={formState.errors}
					type={showPassword ? "text" : "password"}
					rules={{
						required: { value: true, message: "Password is required." },
						minLength: {
							value: 8,
							message: "Password should be at least 8 characters",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="old_password" />

				<Form.Text
					required={true}
					name="new_password1"
					label="New Password"
					control={control}
					id="new-password1-input"
					errors={formState.errors}
					type={showPassword ? "text" : "password"}
					rules={{
						required: { value: true, message: "New Password is required." },
						validate: {
							lenght: (v: string) =>
								v.length >= 8 || "Password should be at least 8 characters",
							number: (v: string) =>
								/\d/gm.test(v) || "Password should have at least 1 number.",
							letter: (v: string) =>
								/\D/gm.test(v) || "Password should have at least 1 letter.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="new_password1" />

				<Form.Text
					required={true}
					name="new_password2"
					control={control}
					id="new-password2-input"
					label="Verifiy New Password"
					errors={formState.errors}
					type={showPassword ? "text" : "password"}
					rules={{
						required: {
							value: true,
							message: "Verify New Password is required.",
						},
						validate: {
							lenght: (v: string) =>
								v.length >= 8 || "Password should be at least 8 characters",
							number: (v: string) =>
								/\d/gm.test(v) || "Password should have at least 1 number.",
							letter: (v: string) =>
								/\D/gm.test(v) || "Password should have at least 1 letter.",
							verify: (v: string) =>
								v === password1 || "Password should be the same.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="new_password2" />

				<div className="flex flex-row items-center">
					<Checkbox
						label="Show password"
						checked={showPassword}
						id="show-password-checkbox"
						onChange={handleShowPassword}
					/>
				</div>

				<div className="mt-1 text-sm text-center font-semibold text-red-600">
					{error.msg !== "" && (
						<>
							{error.generic && (
								<p>Something went wrong, please try again later!</p>
							)}
							<p>{error.msg}</p>
						</>
					)}
				</div>
			</div>

			<div className="mt-5 sm:flex sm:flex-row-reverse">
				<Button
					form={formId}
					type="submit"
					value="submit"
					id="create-item-submit-button"
					disabled={!formState.isValid}
					className={`
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
				>
					Change Password
				</Button>
			</div>
		</Form>
	);
}

export default ChangePasswordModal;
