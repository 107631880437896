import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Arrow, Brand } from "../../../Icons";

import { Api } from "../../../../services";
import { useUserContext } from "../../../../providers/UserProvider";
import { Option, VehicleDTO } from "../../../../services/types";
import BranchTag from "../../../RentaCar/BranchTag";
import { Button, Link, ModalType } from "../../../Common";
import { useModalContext } from "../../../../providers/ModalProvider";
import Add from "../../../Icons/Add";
import EmptyList from "../../../Common/EmptyList";
import VehiclesList from "./VehiclesList";
import { useRentCarContext } from "../../../../providers/RentCarProvider";
import { VehicleType } from "../Modals/AddUpdateVehicleModal";

function RentCarVehicles() {
	const { user } = useUserContext();
	const navigate = useNavigate();
	const { state } = useLocation();

	const modalContext = useModalContext();

	const { branchId } = useParams();

	const apiCall = useRef<boolean>(false);

	const {
		categories,
		colors,
		models,
		handleSetCategories,
		handleSetColors,
		handleSetModels,
		handleSetQrs,
		qrs,
	} = useRentCarContext();
	const [vehiclesByPartner, setVehiclesByPartner] = useState<VehicleDTO[]>([]);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const categoriesByVehicle: Option[] = useMemo(() => {
		return Object.keys(categories).map((key) => ({
			label: categories[Number(key)],
			value: Number(key),
		}));
	}, [categories]);

	const colorsByVehicle: Option[] = useMemo(() => {
		return Object.values(colors).map((value) => ({
			label: value,
			value: value,
		}));
	}, [colors]);

	const modelsByVehicle: Option[] = useMemo(() => {
		return Object.values(models).map((value) => ({
			label: value.toString(),
			value: value.toString(),
		}));
	}, [models]);

	const filteredQrsByPartner: Option[] = useMemo(() => {
		return Object.keys(qrs)
			.filter((key) => !qrs[Number(key)].hasVehicle)
			.map((key) => ({
				label: qrs[Number(key)].code,
				value: Number(key),
			}));
	}, [qrs]);

	const qrsByPartner: Option[] = useMemo(() => {
		return Object.keys(qrs)
			.filter((key) => !qrs[Number(key)].hasVehicle)
			.map((key) => ({
				label: qrs[Number(key)].code,
				value: Number(key),
			}));
	}, [qrs]);

	const handleOnClickVehicleOptions = <T extends VehicleDTO>(
		data: T,
		modalType: ModalType = ModalType.RENT_HIRE_VEHICLE,
		title: string
	) => {
		modalContext.openModalHandler({
			type: modalType,
			data: {
				title,
				data:
					modalType === ModalType.ADD_UPDATE_VEHICLE
						? {
								categories: categoriesByVehicle,
								colors: colorsByVehicle,
								models: modelsByVehicle,
								qrs: filteredQrsByPartner,
								vehicle: data,
						  }
						: data,
			},
		});
	};

	useEffect(() => {
		const getVehicles = async () => {
			try {
				apiCall.current = true;
				const {
					ok: vehiclesStatusReponse,
					error: vehiclesStatusError,
					vehicles,
				} = await Api.getAllVehiclesByBranch(Number(branchId));

				if (vehiclesStatusReponse && vehicles) {
					setVehiclesByPartner(vehicles);
				} else
					setError({
						msg: vehiclesStatusError!,
					});

				if (
					Object.keys(categories).length === 0 &&
					Object.keys(colors).length === 0 &&
					Object.keys(models).length === 0
				)
					getQrsAndAdditionalData();
			} catch (e) {
				const error = e as Error;
				setError({ msg: error.message, generic: true });
			} finally {
				apiCall.current = false;
			}
		};

		const getQrsAndAdditionalData = async () => {
			try {
				apiCall.current = true;
				const {
					ok: categoriesStatusResponse,
					error: categoriesStatusError,
					categories,
				} = await Api.getCategoriesByVehicle();

				const {
					ok: colorsStatusResponse,
					error: colorsStatusError,
					colors,
				} = await Api.getColorsByVehicle();

				const {
					ok: modelsStatusResponse,
					error: modelsStatusError,
					models,
				} = await Api.getModelsByVehicle();

				if (user.member?.isSuperUser && Object.keys(qrs).length === 0) {
					const {
						ok: qrsStatusResponse,
						error: qrsStatusError,
						qrs,
					} = await Api.getQRsByPartner();

					if (qrsStatusResponse && qrs) {
						const qrsByPartner = qrs
							.filter((qr) => !qr.hasVehicle)
							.reduce((acc, { id, code, hasVehicle }) => {
								acc[id] = { code, hasVehicle: hasVehicle! };
								return acc;
							}, {} as Record<number, { code: string; hasVehicle: boolean }>);

						handleSetQrs(qrsByPartner);
					} else
						setError({
							msg: qrsStatusError!,
						});
				}

				if (
					categoriesStatusResponse &&
					categories &&
					colorsStatusResponse &&
					colors &&
					modelsStatusResponse &&
					models
				) {
					const categoriesByVehicle = categories.reduce((acc, category) => {
						acc[category.id] = category.name;
						return acc;
					}, {} as Record<number, string>);

					const colorsByVehicle = colors.reduce((acc, color, index) => {
						acc[index] = color;
						return acc;
					}, {} as Record<number, string>);

					const modelsByVehicle = models.reduce((acc, model, index) => {
						acc[index] = model;
						return acc;
					}, {} as Record<number, string>);

					handleSetCategories(categoriesByVehicle);
					handleSetColors(colorsByVehicle);
					handleSetModels(modelsByVehicle);
				} else
					setError({
						msg:
							categoriesStatusError! ||
							colorsStatusError! ||
							modelsStatusError!,
					});
			} catch (e) {
				const error = e as Error;
				setError({ msg: error.message, generic: true });
			} finally {
				apiCall.current = false;
			}
		};

		if (!apiCall.current) getVehicles();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [branchId, user.member?.isSuperUser]);

	const handleOpenAddVehicleModal = () => {
		setError({
			msg: "",
		});

		modalContext.openModalHandler({
			type: ModalType.ADD_UPDATE_VEHICLE,
			data: {
				title: "Add Vehicle",
				data: {
					categories: categoriesByVehicle,
					colors: colorsByVehicle,
					models: modelsByVehicle,
					qrs: qrsByPartner,
				},
			},
		});
	};

	const patchVehicleByBranch = useCallback(
		async (vehicleToPatch: VehicleDTO) => {
			try {
				apiCall.current = true;
				const {
					ok,
					error,
					vehicle: patchedVehicle,
				} = await Api.PatchVehicleByBranch(vehicleToPatch);

				if (!ok) {
					setError((prevError) => ({ ...prevError, msg: error! }));
				} else {
					if (patchedVehicle && qrs) {
						setVehiclesByPartner((prevVehicles) =>
							prevVehicles.map((vehicle) =>
								vehicle.id === patchedVehicle!.id
									? {
											...patchedVehicle,
											code: vehicle.code,
									  }
									: vehicle
							)
						);
					}
				}
			} catch (e) {
				const error = e as Error;

				setError((prevError) => ({
					...prevError,
					msg: error.message,
					generic: true,
				}));
			} finally {
				apiCall.current = false;
			}
		},
		[qrs]
	);

	const addVehicleByBranch = useCallback(async (newVehicle: VehicleDTO) => {
		try {
			apiCall.current = true;
			const { ok, error, vehicle } = await Api.AddVehicleByBranch(newVehicle);

			if (!ok) {
				setError((prevError) => ({ ...prevError, msg: error! }));
			} else {
				if (vehicle) {
					setVehiclesByPartner((prevVehicles) => [
						...prevVehicles,
						{
							...vehicle,
							code: newVehicle.code,
							category: vehicle.category ?? 0,
						},
					]);
				}
			}
		} catch (e) {
			const error = e as Error;

			setError((prevError) => ({
				...prevError,
				msg: error.message,
				generic: true,
			}));
		} finally {
			apiCall.current = false;
		}
	}, []);

	const deleteVehicleByBranch = useCallback(async (vehicleId: number) => {
		try {
			apiCall.current = true;
			const { ok, error } = await Api.deleteVehicleByBranch(vehicleId);

			if (!ok) {
				setError((prevError) => ({ ...prevError, msg: error! }));
			} else {
				setVehiclesByPartner((prevVehicles) =>
					prevVehicles.filter((vehicle) => vehicle.id !== vehicleId)
				);
			}
		} catch (e) {
			const error = e as Error;
			setError((prevError) => ({
				...prevError,
				msg: error.message,
				generic: true,
			}));
		} finally {
			apiCall.current = false;
		}
	}, []);

	useEffect(() => {
		if (modalContext.modalResponse === ModalType.ADD_UPDATE_VEHICLE) {
			const { isNewVehicle, vehicle } = modalContext.modalResponseData as {
				isNewVehicle: boolean;
				vehicle: VehicleType | VehicleDTO;
			};

			if (!apiCall.current) {
				if (isNewVehicle) {
					const vehicleFromModal = vehicle as VehicleType;

					const vehicleDTO: VehicleDTO = {
						...vehicleFromModal,
						category: vehicleFromModal.category.value as number,
						color: vehicleFromModal.color.value as string,
						model: vehicleFromModal.model.value as string,
						code: vehicleFromModal.code.label,
						branch: Number(branchId),
						booked: false,
						bookedEmail: "",
						bookedPhoneNumber: "",
					};
					addVehicleByBranch(vehicleDTO);
				} else {
					const vehicleFromModal = vehicle as VehicleType;

					const vehicleDTO: VehicleDTO = {
						id: vehicleFromModal.id,
						brand: vehicleFromModal.brand,
						category: vehicleFromModal.category.value as number,
						color: vehicleFromModal.color.value as string,
						model: vehicleFromModal.model.value as string,
					};

					patchVehicleByBranch(vehicleDTO as VehicleDTO);
				}
			}
		}

		if (modalContext.modalResponse === ModalType.RENT_HIRE_VEHICLE) {
			const vehicle = modalContext.modalResponseData as VehicleDTO;

			if (!apiCall.current) patchVehicleByBranch(vehicle);
		}

		if (modalContext.modalResponse === ModalType.DELETE_VEHICLE) {
			const vehicle = modalContext.modalResponseData as VehicleDTO;

			if (!apiCall.current) deleteVehicleByBranch(vehicle.id!);
		}

		modalContext.clearModalHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalContext.modalResponse]);

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        min-h-max
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-lg mb-4">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
			</div>
			<div
				className={`flex flex-col items-center justify-center gap-y-2 ${
					!user.member?.isSuperUser && "mb-3"
				}`}
			>
				<h2 className="text-black tracking-tight">
					<span className="text-2xl font-bold">
						{user.member?.partner.name.toLocaleUpperCase()}
					</span>
				</h2>
				<BranchTag branchName={state.branchName} />
			</div>

			<div className="w-full my-3 flex items-center justify-between flex-1">
				<Link
					icon={true}
					iconPosition="start"
					onClick={() => navigate("/app/partner", {})}
					customIcon={<Arrow size={20} color="fill-current" />}
				/>
				{user.member?.isSuperUser && (
					<Button
						className=" 
              bg-green-500
              text-white
              font-semibold
              border-green-500
              hover:bg-green-600
              p-2 
              rounded-md 
              flex 
              items-center
              gap-x-1
            "
						onClick={handleOpenAddVehicleModal}
					>
						Add New Vehicle <Add size={20} color="fill-current" />
					</Button>
				)}
			</div>

			{vehiclesByPartner.length > 0 && (
				<VehiclesList
					vehicles={vehiclesByPartner}
					handleOnClickVehicleOptions={handleOnClickVehicleOptions}
					categoriesByVehicle={categories}
				/>
			)}

			{vehiclesByPartner.length === 0 && (
				<EmptyList
					parent="branch"
					child="vehicles"
					complementary="Please contact your branch manager and ask for support."
				/>
			)}

			<div className="mt-1 text-sm text-center font-semibold text-red-600">
				{error.msg !== "" && (
					<>
						{error.generic && (
							<p>Something went wrong, please try again later!</p>
						)}
						<p>{error.msg}</p>
					</>
				)}
			</div>
		</div>
	);
}

export default RentCarVehicles;
