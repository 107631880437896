import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { useUserContext } from "../../../../providers/UserProvider";
import { Button, Form, ModalProps, ModalType } from "../../../Common";
import BranchTag from "../../../RentaCar/BranchTag";
import { getPathToBranch } from "../../../../services/utils";

type SelectOptionToManageProps = {
	info: {
		branchId: number;
		branchName: string;
	};
	onCloseModal: ModalProps["onCloseModal"];
};

type Option = {
	value: number;
	label: string;
};

export type SelectOptionToManageInput = {
	option: Option;
};

const optionsToManage: Option[] = [
	{
		value: 1,
		label: "Members",
	},
	{
		value: 2,
		label: "Vehicles",
	},
];

function SelectOptionToManageModal({
	info: { branchId, branchName },
	onCloseModal,
}: SelectOptionToManageProps) {
	const formId = "select-option-to-manage-form";

	const navigate = useNavigate();
	const { handleUpdateMemberBranch } = useUserContext();

	const { watch, handleSubmit, control, formState } =
		useForm<SelectOptionToManageInput>({
			mode: "onChange",
			defaultValues: {
				option: optionsToManage[0],
			},
		});

	const watchForm = watch(["option"]);

	const onSubmit: SubmitHandler<SelectOptionToManageInput> = async (form) => {
		const selectedOption = form.option;

		onCloseModal({
			type: ModalType.SELECT_OPTION_TO_MANAGE,
			data: selectedOption,
		});

		handleUpdateMemberBranch(branchId);
		const url = getPathToBranch({
			branchId,
			finalPath: selectedOption.label,
		});
		navigate(url, { relative: "path", state: { branchName } });
	};

	const fetchOptionsToManage = (
		search: string,
		callback: (options: Option[]) => void
	) => {
		callback(
			optionsToManage.filter((option) =>
				option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
			)
		);
	};

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	if (optionsToManage.length === 0) return null;
	return (
		<>
			<BranchTag branchName={branchName} width="full" />
			<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
				<div className="h-30 max-h-modal-container">
					<Form.Select
						name="option"
						required={true}
						label="Options to manage"
						control={control}
						id="option-to-manage-input"
						errors={formState.errors}
						loadOptions={fetchOptionsToManage}
						defaultValue={optionsToManage[0]}
						rules={{
							required: {
								value: true,
								message: "Option to manage is required.",
							},
						}}
					/>
				</div>

				<div className="mt-5 sm:flex sm:flex-row-reverse">
					<Button
						form={formId}
						type="submit"
						value="submit"
						id="options-to-manage-submit-button"
						disabled={!formState.isValid}
						className={`
            flex
            mt-5
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
					>
						Go to {watchForm[0].value === 1 ? "Members" : "Vehicles"}
					</Button>
				</div>
			</Form>
		</>
	);
}

export default SelectOptionToManageModal;
