import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Form, ModalProps, ModalType } from "../../../Common";
import { formatPhoneNumber } from "../../Profile";
import { VehicleDTO } from "../../../../services/types";

type RentHireVehicleModalProps = {
	onCloseModal: ModalProps["onCloseModal"];
	data: VehicleDTO;
};

export type RentHireVehicleType = {
	bookedBy: string;
	bookedEmail: string;
	bookedPhoneNumber: string;
};

const transformPhoneInput = {
	input: (value: string) => {
		if (value !== "") {
			let newValue = value;
			newValue = formatPhoneNumber(newValue)!;
			return newValue ? newValue : value;
		}

		return "";
	},
	output: (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = event.target.value.replace(/\D/g, "");

		return value;
	},
};

function RentHireVehicleModal({
	onCloseModal,
	data,
}: RentHireVehicleModalProps) {
	const { booked, bookedBy, bookedEmail, bookedPhoneNumber, id } = data;
	const formId = "rent-hire-vehicle-form";

	const { handleSubmit, control, formState } = useForm<RentHireVehicleType>({
		mode: "onChange",
		defaultValues: {
			bookedBy: booked ? bookedBy : "",
			bookedEmail: booked ? bookedEmail : "",
			bookedPhoneNumber: booked ? bookedPhoneNumber : "",
		},
	});

	const onSubmit: SubmitHandler<RentHireVehicleType> = async (form) => {
		onCloseModal({
			type: ModalType.RENT_HIRE_VEHICLE,
			data: {
				id,
				booked: !booked,
				bookedBy: booked ? "" : form.bookedBy,
				bookedEmail: booked ? "" : form.bookedEmail,
				bookedPhoneNumber: booked ? "" : form.bookedPhoneNumber,
			},
		});
	};

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	return (
		<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
			<div
				className="
          text-sm
          max-h-modal-container
          md:text-base
        "
			>
				<Form.Text
					required={true}
					name="bookedBy"
					disabled={booked}
					control={control}
					label="Renter name"
					id="bookedBy-input"
					errors={formState.errors}
					rules={{
						required: {
							value: true,
							message: "Renter name is required.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="bookedBy" />

				<Form.Text
					required={true}
					name="bookedEmail"
					disabled={booked}
					label="Renter email"
					id="bookedEmail-input"
					control={control}
					errors={formState.errors}
					rules={{
						pattern: {
							value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
							message: "Enter a valid email.",
						},
						required: { value: true, message: "Renter email is required." },
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="bookedEmail" />

				<Form.Text
					required={true}
					control={control}
					name="bookedPhoneNumber"
					disabled={booked}
					id="phone-number-input"
					placeholder="+1 (706) 5554433"
					errors={formState.errors}
					transform={transformPhoneInput}
					label="Renter phone Number (Use country code)"
					rules={{
						required: {
							value: true,
							message: "Renter phone number is required.",
						},
						maxLength: {
							value: 13,
							message: "Phone Number is too long.",
						},
						pattern: {
							value:
								/\+?\d{1,3}?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/,
							message: "Renter phone Number is invalid.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="bookedPhoneNumber" />
			</div>

			<div
				className="mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse"
			>
				<Button
					form={formId}
					type="submit"
					value="submit"
					id="rent-hire-vehicle-submit-button"
					disabled={!formState.isValid}
					className={`
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
				>
					{booked ? "Hire" : "Rent"} vehicle
				</Button>
				<Button
					id="cancel-new-member-modal-button"
					onClick={() => onCloseModal({ type: "", data: null })}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
				>
					Cancel
				</Button>
			</div>
		</Form>
	);
}

export default RentHireVehicleModal;
