import { Brand } from "../../Icons";
import { Button, Divider, ModalType } from "../../Common";

import { useUserContext } from "../../../providers/UserProvider";
import { useModalContext } from "../../../providers/ModalProvider";

export function formatPhoneNumber(phoneNumberString?: string) {
	if (!phoneNumberString) return null;

	var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
	var match = cleaned.match(/^(\d{1,3})?(\d{3})(\d{3})(\d{4})$/);
	if (match) {
		var intlCode = match[1] ? `+${match[1]} ` : "";
		return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
	}
}

function Profile() {
	const { user } = useUserContext();

	const modalContext = useModalContext();

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        min-h-max
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-lg">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<div className="mt-5 flex flex-row items-center justify-center">
					<h2 className="text-black tracking-tight">
						<span className="text-2xl font-bold">Profile</span>
					</h2>
				</div>
			</div>

			<div className="p-5 sm:mx-auto sm:w-full sm:max-w-lg rounded-md shadow-md">
				<div
					className="
            mt-5
            flex
            flex-col
            space-x-0
            space-y-2
            sm:flex-row
            sm:items-center
            sm:space-x-2
            sm:space-y-0
          "
				>
					<div className="w-full text-black sm:w-1/2 overflow-hidden">
						<p className="text-sm md:text-base">First Name</p>
						<p className="text-lg md:text-xl font-bold truncate">
							{user.firstName || "N/A"}
						</p>
					</div>
					<div className="w-full text-black sm:w-1/2 overflow-hidden">
						<p className="text-sm md:text-base">Last Name</p>
						<p className="text-lg md:text-xl font-bold truncate">
							{user.lastName || "N/A"}
						</p>
					</div>
				</div>

				<div className="flex flex-col">
					<h2 className="mb-3 mt-6 text-2xl font-bold">Contact Info</h2>
					<div className="text-black overflow-hidden">
						<p className="text-sm md:text-base">Email</p>
						<p className="text-lg md:text-xl font-bold truncate">
							{user.email || "N/A"}
						</p>
					</div>
					<div
						className="
              mt-3
              flex
              flex-col
              space-x-0
              space-y-2
              sm:flex-row
              sm:items-center
              sm:space-x-2
              sm:space-y-0
            "
					>
						<div className="w-full text-black sm:w-1/2 overflow-hidden">
							<p className="text-sm md:text-base">Phone Number</p>
							<p className="text-lg md:text-xl font-bold truncate">
								{formatPhoneNumber(user.phoneNumber) || "N/A"}
							</p>
						</div>
						<div className="w-full text-black sm:w-1/2 overflow-hidden">
							<p className="text-sm md:text-base">Alternative Phone Number</p>
							<p className="text-lg md:text-xl font-bold truncate">
								{formatPhoneNumber(user.phoneNumberAlt) || "N/A"}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div className="mt-5 w-full flex flex-col sm:mx-auto sm:max-w-xs">
				<Button
					onClick={() =>
						modalContext.openModalHandler({
							type: ModalType.UPDATE_PROFILE,
							data: { title: "Update Profile" },
						})
					}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
          "
				>
					Edit Profile
				</Button>
				<Divider text="O" />
				<Button
					onClick={() =>
						modalContext.openModalHandler({
							type: ModalType.CHANGE_PASSWORD,
							data: { title: "Change Password" },
						})
					}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
          "
				>
					Change Password
				</Button>
			</div>
		</div>
	);
}

export default Profile;
