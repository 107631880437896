import { useCallback, useEffect, useRef, useState } from "react";

import { Brand } from "../../../Icons";

import { Api } from "../../../../services";
import { useUserContext } from "../../../../providers/UserProvider";
import {
	BranchDTO,
	BranchRoleDTO,
	PatchBranchByPartnerInputs,
} from "../../../../services/types";
import BranchesList from "../Branches/BranchesList";
import EmptyList from "../../../Common/EmptyList";
import { useModalContext } from "../../../../providers/ModalProvider";
import { Button, ModalType } from "../../../Common";
import Add from "../../../Icons/Add";

function RentCarDashboard() {
	const { user } = useUserContext();
	const modalContext = useModalContext();
	const apiCall = useRef<boolean>(false);
	const [userBranchRoles, setuserBranchRoles] = useState<BranchRoleDTO[]>([]);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	useEffect(() => {
		const getBranchesByUser = async () => {
			try {
				apiCall.current = true;
				const { ok, branchRoles, error } = await Api.getBranchesByUser(
					user.member?.id!
				);

				if (ok && branchRoles) {
					setuserBranchRoles(branchRoles);
				} else setError({ msg: error! });
			} catch (e) {
				const error = e as Error;
				setError({ msg: error.message, generic: true });
			} finally {
				apiCall.current = false;
			}
		};

		if (!apiCall.current) getBranchesByUser();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const addBranchByPartner = useCallback(async (newBranch: BranchDTO) => {
		try {
			apiCall.current = true;
			const { ok, error, branchRole } = await Api.AddBranchByPartner(newBranch);

			if (!ok) {
				setError((prevError) => ({ ...prevError, msg: error! }));
			} else {
				setuserBranchRoles((prevMembers) => [
					...prevMembers,
					branchRole as BranchRoleDTO,
				]);
			}
		} catch (e) {
			const error = e as Error;
			setError((prevError) => ({
				...prevError,
				msg: error.message,
				generic: true,
			}));
		} finally {
			apiCall.current = false;
		}
	}, []);

	const updateBranchByPartner = useCallback(
		async ({ branch, branchRole }: PatchBranchByPartnerInputs) => {
			try {
				apiCall.current = true;

				const {
					ok,
					error,
					branchRole: branchRoleUpdated,
				} = await Api.patchBranchByPartner({ branch, branchRole });

				if (!ok) {
					setError((prevError) => ({ ...prevError, msg: error! }));
				} else {
					setuserBranchRoles((prevUsersBranchRoles) =>
						prevUsersBranchRoles
							.map((branchRole) =>
								branchRole.branch.id === branchRoleUpdated?.branch.id
									? branchRoleUpdated
									: branchRole
							)
							.filter(
								(branchRole): branchRole is BranchRoleDTO =>
									branchRole !== undefined
							)
					);
				}
			} catch (e) {
				const error = e as Error;
				setError((prevError) => ({
					...prevError,
					msg: error.message,
					generic: true,
				}));
			} finally {
				apiCall.current = false;
			}
		},
		[]
	);

	const deleteBranchByPartner = useCallback(async (branch: BranchDTO) => {
		try {
			apiCall.current = true;
			const { ok, error } = await Api.deleteBranchByPartner(branch);

			if (!ok) {
				setError((prevError) => ({ ...prevError, msg: error! }));
			} else {
				setuserBranchRoles((prevUsersBranchRoles) =>
					prevUsersBranchRoles.filter(
						(branchRole) => branchRole.branch.id !== branch.id
					)
				);
			}
		} catch (e) {
			const error = e as Error;
			setError((prevError) => ({
				...prevError,
				msg: error.message,
				generic: true,
			}));
		} finally {
			apiCall.current = false;
		}
	}, []);

	useEffect(() => {
		if (modalContext.modalResponse === ModalType.ADD_UPDATE_BRANCH) {
			const { branch } =
				modalContext.modalResponseData as PatchBranchByPartnerInputs;

			if (branch.isNewBranch) {
				const newBranch: BranchDTO = {
					...branch,
					active: true,
				};

				if (!apiCall.current) addBranchByPartner(newBranch);
			} else {
				const { branch, branchRole } =
					modalContext.modalResponseData as PatchBranchByPartnerInputs;

				if (!apiCall.current) updateBranchByPartner({ branch, branchRole });
			}
		}

		if (modalContext.modalResponse === ModalType.DELETE_BRANCH) {
			const branchRole = modalContext.modalResponseData as BranchRoleDTO;

			if (!apiCall.current) deleteBranchByPartner(branchRole.branch);
		}

		modalContext.clearModalHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalContext.modalResponse]);

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        min-h-max
        justify-center
        lg:px-8
      "
		>
			<div
				className={`sm:mx-auto sm:w-full sm:max-w-lg ${
					!user.member?.isSuperUser && "mb-10"
				}`}
			>
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
				<div className="mt-5 flex flex-row items-center justify-center">
					<h2 className="text-black tracking-tight">
						<span className="text-2xl font-bold">
							{user.member?.partner.name.toLocaleUpperCase()}
						</span>
					</h2>
				</div>
			</div>

			<div className="mt-1 text-sm text-center font-semibold text-red-600">
				{error.msg !== "" && (
					<>
						{error.generic && (
							<p>Something went wrong, please try again later!</p>
						)}
						<p>{error.msg}</p>
					</>
				)}
			</div>

			{user.member?.isSuperUser && (
				<div className="w-full my-3 flex items-center justify-end">
					<Button
						className=" 
              bg-green-500
              text-white
              font-semibold
              border-green-500
              hover:bg-green-600
              p-2 
              rounded-md 
              flex 
              items-center
              gap-x-1
            "
						onClick={() =>
							modalContext.openModalHandler({
								type: ModalType.ADD_UPDATE_BRANCH,
								data: { title: "Add Branch" },
							})
						}
					>
						Add New Branch <Add size={20} color="fill-current" />
					</Button>
				</div>
			)}

			{userBranchRoles.length > 0 && (
				<BranchesList user={user} branches={userBranchRoles} />
			)}

			{userBranchRoles.length === 0 && !apiCall.current && (
				<EmptyList parent="partner" child="branches" />
			)}
		</div>
	);
}

export default RentCarDashboard;
