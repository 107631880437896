import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button, Form } from "../Common";
import { formatPhoneNumber } from "../Pages/Profile";
import { useLoadingContext } from "../../providers/LoadingProvider";
import { VehicleReportInputs } from "../../services/types";
import { Api } from "../../services";

function VehicleReport() {
	const formId = "vehicle-report-form";

	const { qrCode } = useParams();
	const { isLoading } = useLoadingContext();

	const navigate = useNavigate();

	const [reportSend, setReportSend] = useState<boolean>(false);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const { watch, handleSubmit, control, formState } =
		useForm<VehicleReportInputs>({
			mode: "onChange",
			defaultValues: {
				phone_number: "",
				email: "",
				message: "",
			},
		});

	const watchForm = watch(["phone_number", "email", "message"]);

	const onSubmit: SubmitHandler<VehicleReportInputs> = async (form) => {
		setError({ msg: "" });
		const { email, phone_number, message } = form;

		if (email === "" && phone_number === "" && message === "") {
			setError({
				msg: "At least one of the fields must have information to be able to contact you.",
			});
			return;
		}

		const submittedData: VehicleReportInputs = {
			code: qrCode,
			email,
			phone_number,
			message,
		};

		try {
			const { ok, error } = await Api.postVehicleReport(submittedData);

			if (ok) {
				setReportSend(true);
				setTimeout(() => navigate("/", {}), 3000);
			} else setError({ msg: error! });
		} catch (e) {
			const error = e as Error;
			setError({ msg: error.message, generic: true });
		}
	};

	const transformPhoneInput = {
		input: (value: string) => {
			if (value !== "") {
				let newValue = value;
				newValue = formatPhoneNumber(newValue)!;
				return newValue ? newValue : value;
			}

			return "";
		},
		output: (event: React.ChangeEvent<HTMLInputElement>) => {
			const value = event.target.value.replace(/\D/g, "");

			return value;
		},
	};

	const formValid =
		(watchForm[0] !== "" || watchForm[1] !== "" || watchForm[2] !== "") &&
		formState.isValid;
	const disabledSubmitButton =
		!formValid && "opacity-25 cursor-not-allowed pointer-events-none";
	return (
		<div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
			{!isLoading && !reportSend ? (
				<>
					<p className="mb-2 text-lg font-semibold">
						Please leave your contact information, so the owner of the lost car
						keys can get in touch with you.
					</p>
					<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
						<Form.Text
							type="text"
							name="email"
							label="email"
							id="email-input"
							required={false}
							control={control}
							errors={formState.errors}
							rules={{
								pattern: {
									value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
									message: "Enter a valid email.",
								},
							}}
						/>
						<Form.ErrorMessage errors={formState.errors} name="email" />
						<Form.Text
							required={false}
							control={control}
							name="phone_number"
							id="phone-number-input"
							placeholder="17065554433"
							errors={formState.errors}
							transform={transformPhoneInput}
							label="Phone Number (Use country code)"
							rules={{
								maxLength: {
									value: 13,
									message: "Phone Number is too long.",
								},
								pattern: {
									value:
										/\+?\d{1,3}?\s*\(?-*\.*(\d{3})\)?\.*-*\s*(\d{3})\.*-*\s*(\d{4})$/,
									message: "Phone Number is invalid.",
								},
							}}
						/>
						<Form.ErrorMessage errors={formState.errors} name="phone_number" />

						<Form.Textarea
							rows={4}
							name="message"
							label="Message"
							required={false}
							control={control}
							id="message-input"
							errors={formState.errors}
						/>

						<div className="mt-1 text-sm text-center font-semibold text-red-600">
							{error.msg !== "" && (
								<>
									{error.generic && (
										<p>Something went wrong, please try again later!</p>
									)}
									<p>{error.msg}</p>
								</>
							)}
						</div>

						<Button
							form={formId}
							type="submit"
							value="submit"
							id="found-item-submit-button"
							disabled={!formValid}
							className={`
                flex
                mt-10
                w-full
                border-2
                leading-6
                shadow-sm
                rounded-md
                text-white
                font-semibold
                justify-center
                bg-green-600
                border-green-600
                hover:bg-green-500
                ${disabledSubmitButton}
              `}
						>
							Report Lost car keys
						</Button>
					</Form>
				</>
			) : (
				<h2
					className="
            mt-5
            text-2xl
            font-bold
            leading-9
            text-center
            text-black
            tracking-tight
          "
				>
					Report sent successfully.
				</h2>
			)}
		</div>
	);
}

export default VehicleReport;
