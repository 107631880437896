import { Button, ModalProps, ModalType } from "../../../Common";
import { VehicleDTO } from "../../../../services/types";

type DeleteVehicleModalProps = {
	data: VehicleDTO;
	onCloseModal: ModalProps["onCloseModal"];
};

function DeleteVehicleModal({
	data: vehicle,
	onCloseModal,
}: DeleteVehicleModalProps) {
	const handleOnClickDeleteVehicle = async () => {
		if (!vehicle) return;

		onCloseModal({ type: ModalType.DELETE_VEHICLE, data: vehicle });
	};

	return (
		<div className="flex flex-1 flex-col">
			<p
				className="
          text-xl
          font-bold
          leading-9
          text-center
          text-black
          tracking-tight
        "
			>
				<span>
					Are you sure, you want to delete this vehicle from your
					branch/partner?
				</span>
			</p>

			<div
				className="
          mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse
      "
			>
				<Button
					id="delete-vehicle-modal-button"
					onClick={handleOnClickDeleteVehicle}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
          "
				>
					Delete Vehicle
				</Button>
				<Button
					id="cancel-qr-modal-button"
					onClick={() => onCloseModal({ type: "", data: null })}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
				>
					Cancel
				</Button>
			</div>
		</div>
	);
}

export default DeleteVehicleModal;
