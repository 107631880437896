import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Arrow, Brand } from "../../../Icons";

import { Api } from "../../../../services";
import { useUserContext } from "../../../../providers/UserProvider";
import {
	MemberByBranch,
	MemberByBranchDTO,
	MemberByPartnerInputs,
	NewMemberByBranch,
	PostNewBranchRole,
	Role,
	UserDTO,
} from "../../../../services/types";
import MembersList from "./MembersList";
import BranchTag from "../../../RentaCar/BranchTag";
import { Button, Link, ModalType } from "../../../Common";
import { useModalContext } from "../../../../providers/ModalProvider";
import Add from "../../../Icons/Add";
import { NewMemberType } from "../Modals/AddNewMemberModal";
import EmptyList from "../../../Common/EmptyList";

function RentCarMembers() {
	const { user } = useUserContext();
	const navigate = useNavigate();
	const { state } = useLocation();

	const modalContext = useModalContext();

	const { branchId } = useParams();

	const apiCall = useRef<boolean>(false);
	const [branchMembers, setBranchMembers] = useState<MemberByBranchDTO[]>([]);
	const [partnerMembers, setPartnerMembers] = useState<MemberByBranchDTO[]>([]);
	const [error, setError] = useState<{ msg: string; generic?: boolean }>({
		msg: "",
	});

	const handleOnClickMemberOptions = <T extends MemberByBranchDTO | UserDTO>(
		data: T,
		modalType: ModalType = ModalType.DELETE_MEMBER
	) => {
		modalContext.openModalHandler({
			type: modalType,
			data: {
				title: `${
					modalType === ModalType.DELETE_MEMBER ? "Delete" : "Update"
				} member`,
				data,
			},
		});
	};

	const deleteMemberByBranch = useCallback(async (memberId: number) => {
		try {
			apiCall.current = true;
			const { ok, error } = await Api.deleteMemberByBranch(memberId);

			if (!ok) {
				setError((prevError) => ({ ...prevError, msg: error! }));
			} else {
				setBranchMembers((prevMembers) =>
					prevMembers.filter((member) => member.id !== memberId)
				);
			}
		} catch (e) {
			const error = e as Error;
			setError((prevError) => ({
				...prevError,
				msg: error.message,
				generic: true,
			}));
		} finally {
			apiCall.current = false;
		}
	}, []);

	const updateBranchRoleByMember = useCallback(
		async (member: MemberByBranchDTO) => {
			try {
				apiCall.current = true;
				const { ok, error, branchRoleUpdated } =
					await Api.UpdateMemberBranchRole(member.branchRole);

				if (!ok) {
					setError((prevError) => ({ ...prevError, msg: error! }));
				} else {
					setBranchMembers((prevMembers) =>
						prevMembers
							.map((member) =>
								member.branchRole.id === branchRoleUpdated!.id
									? { ...member, branchRole: branchRoleUpdated }
									: member
							)
							.filter(
								(member): member is MemberByBranchDTO => member !== undefined
							)
					);
				}
			} catch (e) {
				const error = e as Error;
				setError((prevError) => ({
					...prevError,
					msg: error.message,
					generic: true,
				}));
			} finally {
				apiCall.current = false;
			}
		},
		[]
	);

	const updateSuperUserByMember = useCallback(
		async (memberPatched: MemberByBranchDTO) => {
			try {
				apiCall.current = true;

				const newSuperUserState = !memberPatched.isSuperUser;
				const { ok, error, branchRoleUpdated } = await Api.UpdateSuperUserState(
					memberPatched.branchRole,
					newSuperUserState
				);

				if (!ok) {
					setError((prevError) => ({ ...prevError, msg: error! }));
				} else {
					setBranchMembers((prevMembers) =>
						prevMembers
							.map((member) =>
								member.branchRole.id === branchRoleUpdated!.id
									? {
											...member,
											branchRole: branchRoleUpdated,
											isSuperUser: newSuperUserState,
									  }
									: member
							)
							.filter(
								(member): member is MemberByBranchDTO => member !== undefined
							)
					);
				}
			} catch (e) {
				const error = e as Error;
				setError((prevError) => ({
					...prevError,
					msg: error.message,
					generic: true,
				}));
			} finally {
				apiCall.current = false;
			}
		},
		[]
	);

	const addMemberByBranch = useCallback(
		async (member: MemberByPartnerInputs) => {
			try {
				apiCall.current = true;

				const postNewBranchRole = {
					branch: Number(branchId),
					member: {
						id: member.member.value,
						user: member.member.user,
						branchRole: member.role.label,
						isSuperUser: member.isSuperUser,
					},
				};
				const { ok, error, memberByBranch } = await Api.postNewBranchRole(
					postNewBranchRole as unknown as PostNewBranchRole
				);

				if (!ok) {
					setError((prevError) => ({ ...prevError, msg: error! }));
				} else {
					setBranchMembers((prevMembers) => [
						...prevMembers,
						memberByBranch as MemberByBranchDTO,
					]);
				}
			} catch (e) {
				const error = e as Error;
				setError((prevError) => ({
					...prevError,
					msg: error.message,
					generic: true,
				}));
			} finally {
				apiCall.current = false;
			}
		},
		[branchId]
	);

	const addNewMemberByBranch = useCallback(
		async (newMember: NewMemberByBranch) => {
			try {
				apiCall.current = true;
				const { ok, error, memberByBranch } = await Api.postNewMemberByBranch(
					newMember
				);

				if (!ok) {
					setError((prevError) => ({ ...prevError, msg: error! }));
				} else {
					setBranchMembers((prevMembers) => [
						...prevMembers,
						memberByBranch as MemberByBranchDTO,
					]);
				}
			} catch (e) {
				const error = e as Error;
				setError((prevError) => ({
					...prevError,
					msg: error.message,
					generic: true,
				}));
			} finally {
				apiCall.current = false;
			}
		},
		[]
	);

	useEffect(() => {
		if (modalContext.modalResponse === ModalType.DELETE_MEMBER) {
			const member = modalContext.modalResponseData as MemberByBranch;

			if (!apiCall.current) deleteMemberByBranch(member.id);
		}

		if (modalContext.modalResponse === ModalType.UPDATE_MEMBER) {
			const member = modalContext.modalResponseData as MemberByBranchDTO;

			if (!apiCall.current) updateBranchRoleByMember(member);
		}

		if (modalContext.modalResponse === ModalType.UPDATE_SUPER_USER) {
			const member = modalContext.modalResponseData as MemberByBranchDTO;

			if (!apiCall.current) updateSuperUserByMember(member);
		}

		if (modalContext.modalResponse === ModalType.ADD_MEMBER) {
			const member = modalContext.modalResponseData as MemberByPartnerInputs;

			if (!apiCall.current) addMemberByBranch(member);
		}

		if (modalContext.modalResponse === ModalType.ADD_NEW_MEMBER) {
			const { email, firstName, lastName, isSuperUser, role } =
				modalContext.modalResponseData as NewMemberType;

			const newMember: NewMemberByBranch = {
				email,
				branchRole: role.label as Role,
				branch: Number(branchId),
				firstName,
				lastName,
				isSuperUser,
			};

			if (!apiCall.current) addNewMemberByBranch(newMember);
		}

		modalContext.clearModalHandler();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalContext.modalResponse]);

	useEffect(() => {
		const getMembers = async () => {
			try {
				apiCall.current = true;
				const {
					ok: okMembersByPartner,
					members: membersByPartner,
					error: errorMembersByPartner,
				} = await Api.getMembersByPartner(Number(branchId));
				const {
					ok: okMembersByBranch,
					members: membersByBranch,
					error: errorMembersByBranch,
				} = await Api.getMembersByBranch(Number(branchId));

				if (okMembersByPartner && membersByPartner) {
					setPartnerMembers(membersByPartner);
				} else setError({ msg: errorMembersByPartner! });

				if (okMembersByBranch && membersByBranch) {
					setBranchMembers(membersByBranch);
				} else setError({ msg: errorMembersByBranch! });
			} catch (e) {
				const error = e as Error;
				setError({ msg: error.message, generic: true });
			} finally {
				apiCall.current = false;
			}
		};

		if (user.member?.branchRole.role === Role.STAFF) {
			navigate("/app/partner", {});
		}

		if (!apiCall.current) {
			getMembers();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className="
        px-6
        flex
        py-12
        flex-col
        min-h-max
        justify-center
        lg:px-8
      "
		>
			<div className="sm:mx-auto sm:w-full sm:max-w-lg mb-4">
				<div className="w-20 mx-auto">
					<Brand size={80} color="fill-green-600" />
				</div>
			</div>
			<div className="flex flex-col items-center justify-center gap-y-2">
				<h2 className="text-black tracking-tight">
					<span className="text-2xl font-bold">
						{user.member?.partner.name.toLocaleUpperCase()}
					</span>
				</h2>
				<BranchTag branchName={state.branchName} />
			</div>

			<div className="w-full my-3 flex items-center justify-between flex-1">
				<Link
					icon={true}
					iconPosition="start"
					onClick={() => navigate("/app/partner", {})}
					customIcon={<Arrow size={20} color="fill-current" />}
				/>
				<div className="flex items-center justify-center gap-3">
					{partnerMembers.length > 0 && (
						<Button
							className=" 
            bg-green-500
            text-white
            font-semibold
            border-green-500
            hover:bg-green-600
            p-2 
            rounded-md 
            flex 
            items-center
            gap-x-1
          "
							onClick={() =>
								modalContext.openModalHandler({
									type: ModalType.ADD_MEMBER,
									data: {
										title: "Add Member",
										data: {
											members: partnerMembers.map((member) => ({
												value: member.id,
												label: `${member.user.firstName} ${member.user.lastName}`,
												user: member.user,
											})),
											isSuperUser: user.member?.isSuperUser,
										},
									},
								})
							}
						>
							Add Member
						</Button>
					)}
					<Button
						className=" 
            bg-green-500
            text-white
            font-semibold
            border-green-500
            hover:bg-green-600
            p-2 
            rounded-md 
            flex 
            items-center
            gap-x-1
          "
						onClick={() =>
							modalContext.openModalHandler({
								type: ModalType.ADD_NEW_MEMBER,
								data: {
									title: "Add New Member",
									data: user.member?.isSuperUser,
								},
							})
						}
					>
						Add New Member <Add size={20} color="fill-current" />
					</Button>
				</div>
			</div>

			{branchMembers.length > 0 ? (
				<MembersList
					branchMembers={branchMembers}
					handleOnClickMemberOptions={handleOnClickMemberOptions}
					user={user}
				/>
			) : (
				<EmptyList parent="branch" child="members" />
			)}

			<div className="mt-1 text-sm text-center font-semibold text-red-600">
				{error.msg !== "" && (
					<>
						{error.generic && (
							<p>Something went wrong, please try again later!</p>
						)}
						<p>{error.msg}</p>
					</>
				)}
			</div>
		</div>
	);
}

export default RentCarMembers;
