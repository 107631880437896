import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Form, ModalProps, ModalType } from "../../../Common";
import { RoleOption } from "../../../../services/types";
import { ROLES } from "../../../../utils";

type AddNewMemberModalProps = {
	onCloseModal: ModalProps["onCloseModal"];
	data: boolean;
};

export type NewMemberType = {
	email: string;
	firstName: string;
	lastName: string;
	role: RoleOption;
	isSuperUser: boolean;
};

function AddNewMemberModal({
	onCloseModal,
	data: isSuperUser,
}: AddNewMemberModalProps) {
	const formId = "add-new-member-form";

	const { handleSubmit, control, formState, watch, setValue } =
		useForm<NewMemberType>({
			mode: "onChange",
			defaultValues: {
				firstName: "",
				lastName: "",
				email: "",
				role: ROLES[0],
				isSuperUser: false,
			},
		});

	const watchIsSuperUser = watch("isSuperUser");

	const onSubmit: SubmitHandler<NewMemberType> = async (form) => {
		onCloseModal({
			type: ModalType.ADD_NEW_MEMBER,
			data: { ...form },
		});
	};

	useEffect(() => {
		if (watchIsSuperUser) setValue("role", ROLES[0]);
	}, [watchIsSuperUser, setValue]);

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	const fetchRoles = (
		search: string,
		callback: (options: RoleOption[]) => void
	) => {
		const options: RoleOption[] = ROLES.map(({ value, label }) => ({
			value,
			label,
		}));

		callback(
			options.filter((option) =>
				option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
			)
		);
	};

	return (
		<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
			<div
				className="
          text-sm
          max-h-modal-container
          md:text-base
        "
			>
				<Form.Text
					required={true}
					name="firstName"
					control={control}
					label="First Name"
					id="first-name-input"
					errors={formState.errors}
					rules={{
						required: {
							value: true,
							message: "First Name is required.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="firstName" />
				<Form.Text
					required={true}
					name="lastName"
					control={control}
					label="Last Name"
					id="last-name-input"
					errors={formState.errors}
					rules={{
						required: {
							value: true,
							message: "Last Name is required.",
						},
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="lastName" />

				<Form.Text
					required={true}
					name="email"
					label="Email"
					id="email-input"
					control={control}
					errors={formState.errors}
					rules={{
						pattern: {
							value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
							message: "Enter a valid email.",
						},
						required: { value: true, message: "Email is required." },
					}}
				/>
				<Form.ErrorMessage errors={formState.errors} name="email" />

				{isSuperUser && (
					<Form.Checkbox
						control={control}
						id="super-user-checkbox"
						label="Super User"
						name="isSuperUser"
					/>
				)}

				<Form.Select
					name="role"
					required={true}
					label="Role"
					control={control}
					disabled={watchIsSuperUser}
					id="role-input"
					errors={formState.errors}
					loadOptions={fetchRoles}
					defaultValue={ROLES[0]}
					rules={{
						required: {
							value: true,
							message: "Role is required.",
						},
					}}
				/>
			</div>

			<div
				className="mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse"
			>
				<Button
					form={formId}
					type="submit"
					value="submit"
					id="create-item-submit-button"
					disabled={!formState.isValid}
					className={`
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
				>
					Add member
				</Button>
				<Button
					id="cancel-new-member-modal-button"
					onClick={() => onCloseModal({ type: "", data: null })}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
				>
					Cancel
				</Button>
			</div>
		</Form>
	);
}

export default AddNewMemberModal;
