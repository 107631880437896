import { MemberByBranchDTO, Role, UserDTO } from "../../../../services/types";
import { ModalType } from "../../../Common";
import Member from "./Member";

type MembersListProps = {
	branchMembers: MemberByBranchDTO[];
	handleOnClickMemberOptions: (
		member: MemberByBranchDTO,
		modalType: ModalType
	) => void;
	user: UserDTO;
};
function MembersList({
	branchMembers,
	handleOnClickMemberOptions,
	user,
}: MembersListProps) {
	return (
		<>
			<table className="table w-full text-sm table-fixed">
				<thead className="text-white bg-green-600">
					<tr>
						<th
							style={{ width: "5%" }}
							className="py-3 text-center border-r-white"
						>
							#
						</th>
						<th style={{ width: "35%" }} className="text-center border-r-white">
							Full Name
						</th>
						<th style={{ width: "25%" }} className="text-center border-r-white">
							Role
						</th>
						<th
							style={{
								width: `${
									user.member?.branchRole.role === Role.ADMIN ? "30%" : "10%"
								}`,
							}}
							className="text-center border-r-white"
						>
							Actions
						</th>
					</tr>
				</thead>

				<tbody className="mt-8 space-y-4">
					{branchMembers.map((member, index) => (
						<Member
							index={index}
							key={member.id}
							member={member}
							handleOnClickMemberOptions={handleOnClickMemberOptions}
							user={user}
						/>
					))}
				</tbody>
			</table>
		</>
	);
}

export default MembersList;
