import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { Button, Form, ModalProps, ModalType } from "../../../Common";
import {
	Option,
	PartialMemberDTO,
	RoleOption,
} from "../../../../services/types";
import { ROLES } from "../../../../utils";

type AddMemberModalData = {
	branchId: number;
	members: Option[];
	isSuperUser: boolean;
};

type AddMemberModalProps = {
	onCloseModal: ModalProps["onCloseModal"];
	data: AddMemberModalData;
};

export type MemberByPartnerInputs = {
	member: RoleOption & { user: PartialMemberDTO };
	role: RoleOption;
	isSuperUser?: boolean;
};

function AddMemberModal({ onCloseModal, data }: AddMemberModalProps) {
	const formId = "add-member-form";

	const { members, isSuperUser } = data;

	const { handleSubmit, control, formState, watch, setValue } =
		useForm<MemberByPartnerInputs>({
			mode: "onChange",
			defaultValues: {
				role: ROLES[0],
				isSuperUser: false,
			},
		});

	const watchIsSuperUser = watch("isSuperUser");

	const onSubmit: SubmitHandler<MemberByPartnerInputs> = async (form) => {
		onCloseModal({
			type: ModalType.ADD_MEMBER,
			data: { ...form },
		});
	};

	useEffect(() => {
		if (watchIsSuperUser) setValue("role", ROLES[0]);
	}, [watchIsSuperUser, setValue]);

	const disabledSubmitButton =
		!formState.isValid && "opacity-25 cursor-not-allowed pointer-events-none";

	const fetchOptionsList = (
		optionsList: Option[],
		search: string,
		callback: (options: Option[]) => void
	) => {
		callback(
			optionsList.filter((option) =>
				option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase())
			)
		);
	};

	return (
		<Form id={formId} onSubmit={handleSubmit(onSubmit)}>
			<div
				className="
          text-sm
          max-h-modal-container
          md:text-base
        "
			>
				<Form.Select
					name="member"
					required={true}
					label="Member"
					control={control}
					id="member-input"
					errors={formState.errors}
					loadOptions={(search, callback) =>
						fetchOptionsList(members, search, callback)
					}
					rules={{
						required: {
							value: true,
							message: "Member is required.",
						},
					}}
				/>

				{isSuperUser && (
					<Form.Checkbox
						control={control}
						id="super-user-checkbox"
						label="Super User"
						name="isSuperUser"
					/>
				)}

				<Form.Select
					name="role"
					required={true}
					label="Role"
					control={control}
					disabled={watchIsSuperUser}
					id="role-input"
					errors={formState.errors}
					loadOptions={(search, callback) =>
						fetchOptionsList(ROLES, search, callback)
					}
					defaultValue={ROLES[0]}
					rules={{
						required: {
							value: true,
							message: "Role is required.",
						},
					}}
				/>
			</div>

			<div
				className="mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse"
			>
				<Button
					form={formId}
					type="submit"
					value="submit"
					id="create-item-submit-button"
					disabled={!formState.isValid}
					className={`
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
            ${disabledSubmitButton}
          `}
				>
					Add member
				</Button>
				<Button
					id="cancel-new-member-modal-button"
					onClick={() => onCloseModal({ type: "", data: null })}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
				>
					Cancel
				</Button>
			</div>
		</Form>
	);
}

export default AddMemberModal;
