import { Button, ModalProps, ModalType } from "../../../Common";
import { MemberByBranchDTO } from "../../../../services/types";

type DeleteMemberModalProps = {
	data: MemberByBranchDTO;
	onCloseModal: ModalProps["onCloseModal"];
};

function DeleteMemberModal({
	data: member,
	onCloseModal,
}: DeleteMemberModalProps) {
	const handleOnClickDeleteMember = async () => {
		if (!member) return;

		onCloseModal({ type: ModalType.DELETE_MEMBER, data: member });
	};

	return (
		<div className="flex flex-1 flex-col">
			<p
				className="
          text-xl
          font-bold
          leading-9
          text-center
          text-black
          tracking-tight
        "
			>
				<span>Are you sure, you want to delete: </span>
				<strong className="text-green-600">{member.user.firstName} </strong>
				<span>from your branch?</span>
			</p>
			<p className="text-base leading-8 text-center tracking-tight">
				<span>The member will be removed from all the branches.</span>
			</p>
			<p className="text-base leading-8 text-center tracking-tight">
				<span>This action cannot be undone.</span>
			</p>

			<div
				className="
          mt-5
          space-y-2
          sm:flex
          sm:space-x-2
          sm:space-y-0
          sm:space-x-reverse
          sm:flex-row-reverse
      "
			>
				<Button
					id="add-qr-modal-button"
					onClick={handleOnClickDeleteMember}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            text-white
            font-semibold
            justify-center
            bg-green-600
            border-green-600
            hover:bg-green-500
          "
				>
					Delete Member
				</Button>
				<Button
					id="cancel-qr-modal-button"
					onClick={() => onCloseModal({ type: "", data: null })}
					className="
            flex
            w-full
            border-2
            leading-6
            shadow-sm
            rounded-md
            bg-white
            font-semibold
            justify-center
            text-green-600
            border-green-600
            hover:text-white
            hover:bg-green-500
          "
				>
					Cancel
				</Button>
			</div>
		</div>
	);
}

export default DeleteMemberModal;
